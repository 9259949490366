.granel{

		background: $white-fa;

	&-header-interno_image-description {
  		font-family: $font;
    	font-size: calc(20px + (30 - 20) * ((100vw - 375px) / (1920 - 375)));
    	line-height: 1.5;
    	color: $white;

	}
	.contenedor{
    	position: relative;
    	display: inline-block;
    	text-align: center;
		}

	.texto-encima{
		background: $dark-periwinkle;
	    position: absolute;
	    top: calc(5px + (100 - 5) * ((100vw - 375px) / (1920 - 375)));
	    left: calc(10px + (250 - 10) * ((100vw - 375px) / (1920 - 375)));

	}
	
	.circulo {
	 position: absolute;
	 top: calc(3px + (80 - 3) * ((100vw - 375px) / (1920 - 375)));
	 left: calc(5px + (220 - 5) * ((100vw - 375px) / (1920 - 375)));
     width: calc(40px + (100 - 40) * ((100vw - 375px) / (1920 - 375)));
     height: calc(40px + (100 - 40) * ((100vw - 375px) / (1920 - 375)));
     -moz-border-radius: 50%;
     -webkit-border-radius: 50%;
     border-radius: 50%;
     background: $yellow-orage;
	   
	}
	.ancho{
		width:411px;
	}
	

	.back{

		&-radiu{
			&-medi
			{
				border-radius: 90px 90px 0 0;
			}

			&-40
			{
				border-radius: 40px;	
			}

			&-54
			{
				border-radius: 54px;
			}

			&-60
			{
				border-radius: 60px;
			}
			
		}

		&-pump-orage
		{
			background: $pumpkin-orange;	
		}
	}

	.txt{
		
		&-bold
		{
			font-weight: bold;		
		}

		&-20
		{
			font-size: calc(15px + (20 - 15) * ((100vw - 375px) / (1920 - 375)));
		}

		&-30
		{
			font-size: calc(20px + (30 - 20) * ((100vw - 375px) / (1920 - 375)));
		}

		&-45
		{
			font-size: calc(30px + (45 - 30) * ((100vw - 375px) / (1920 - 375)));
		}

		&-60
		{
			font-size: calc(50px + (60 - 50) * ((100vw - 375px) / (1920 - 375)));
		}

		&-color
		{
			&-white
			{
				color: $white;
			}
			&-white-three
			{
				color: $white-three;
			}
			
		}

	}
	
}


 //@if $breakpoint == phone-small {
   //     @media only screen and (max-width: 20em) { @content }; // 320px
    //}
    //@if $breakpoint == phone-iphone {
      //  @media only screen and (max-width: 23.4375em) { @content }; // 375px
    //}
    //@if $breakpoint == phone {
      //  @media only screen and (max-width: 30em) { @content }; // 480px
    //}
    //@if $breakpoint == tab {
      //  @media only screen and (max-width: 48em) { @content }; // 768px
    //}
    //@if $breakpoint == tab-port {
      //  @media only screen and (max-width: 62em) { @content }; //992px
    //}
    //@if $breakpoint == old-desk {
      //  @media only screen and (max-width: 64em) { @content }; //1024px
    //}
    //@if $breakpoint == tab-land {
      //  @media only screen and (max-width: 75em) { @content }; //1200px
    //}
    //@if $breakpoint == desktop {
      //  @media only screen and (max-width: 90em) { @content }; //1440px
    //}
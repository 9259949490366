// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Roboto', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple-hearth: #6f1fb4;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$yellow-orage: #ffb600;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$grey-cos:#919191;

$purple: #6f1fb4;
$purple-two: #6e29b1;
$pumpkin-orange: #ff8300;
$pumpkin-orange-two: #fd8324;
$white-fa: #fafafa;
$white-three: #808080;
$black: #000000;
$white: #ffffff;
$greyish-brown: #3b3b3b;
$dark-seafoam: #1fb782;
$dark-periwinkle: #5a5ad0;
$greeny-blue: #49c4b6;
$macaroni-and-cheese: #e5af36;
$bright-orange: #ff6a00;
$purpley-grey: #88868a;
$light-teal: #62dade;
$squash: #edbd1b;
$pinky-purple: #c360cb;
$medium-green: #31c131;
$salmon: #ff6969;
$mango: #fc9f34;
$butterscotch: #ffba4b;
$pink-ff:#ff4680;

$white-two:#e2e2e2;
$cerise: #e91e79;
$brownish-grey: #606060;
$white-four:#eaeaea;

// Admin Colors
$pale-grey: #f7f8fa;
$warm-grey: #858585;
$strong-grey: #505050;
$burple: #8935db;
$black-admin: #101010;
$purple-admin-soft: #f0f3f8;


//Transparency's
$inputform-bg: rgba(217, 232, 235, 0.3);


$black-two:#313131;
$french-blue:#4267b2;
$dodger-blue: #3c9cf3;
$verdewp: #18AC30;
$mango-two: #f89d38;

$colors: (
	'blue': $blue,
	'indigo': $indigo,
	'purple': $purple,
	'purple-hearth': $purple-hearth,
	'pink': $pink,
	'red': $red,
	'orange': $orange,
	'yellow': $yellow,
	'green': $green,
	'teal': $teal,
	'cyan': $cyan,
	'black': $black,
	'white': $white,
	'greyish-brown': $greyish-brown,
    'dark-seafoam': $dark-seafoam,
    'greeny-blue': $greeny-blue,
    'macaroni-and-cheese': $macaroni-and-cheese,
    'pumpkin-orange': $pumpkin-orange,
    'pumpkin-orange-two': $pumpkin-orange-two,
    'bright-orange': $bright-orange,
    'purpley-grey': $purpley-grey,
    'light-teal': $light-teal,
    'squash': $squash,
    'pinky-purple': $pinky-purple,
    'medium-green': $medium-green,
    'salmon': $salmon,
    'mango': $mango,
    'butterscoth': $butterscotch,
    'pale-grey': $pale-grey,
    'warm-grey': $warm-grey,
    'strong-grey': $strong-grey,
    'burple': $burple,
    'black-admin': $black-admin,
    'purple-admin-soft': $purple-admin-soft,
	'white-two': $white-two,
	'cerise': $cerise,
	'brownish-grey': $brownish-grey,
	'black-two': $black-two,
	'french-blue':$french-blue,
	'dodger-blue':$dodger-blue,
	'verdewp':$verdewp,
	'mango-two':$mango-two,
);

@each $name, $color in $colors {
	.bg-#{$name}{
		background-color: $color !important;
	}
	.text-#{$name}{
		color: $color !important;
	}
}

.luchamos {
    background-color: $white;
    text-align: center;
    padding-top: calc(120px + (100 - 120) * ((100vw - 375px) / (1920 - 375)));
    padding-bottom: calc(80px + (100 - 80) * ((100vw - 375px) / (1920 - 375)));
    background-image: url("/images/dots/puntos-naranjas-7.svg");
    background-repeat: no-repeat;
    background-position: 70px 244px;

    @include respond(tab) {
        background-image: none;
    }

    & a {
        color: $white;

        :hover {
            text-decoration: none;
        }
    }
}

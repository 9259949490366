.candidatos {

    /* ------ Candidatos Home ------ */

    background: rgb(253,131,36);
    background: linear-gradient(142deg, rgba(253,131,36,1) 35%, rgba(255,78,0,1) 88%);
    text-align: center;

    &-bgBlack {
        position: relative;
        z-index: 0;
        padding: 111px 0 92px;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 29%, rgba(0, 0, 0, 0.44) 100%);

        & p {

            @include respond(desktop) {
                margin-top: 150px;
            }

            @include respond(tab-land) {
                margin-top: 220px;
            }

            @include respond(old-desk) {
                margin-top: 280px;
            }

            @include respond(tab-port) {
                margin-top: calc(10px + (290 - 10) * ((100vw - 375px) / (992 - 375)));
            }

            /*@include respond(tab) {
                margin-top: 220px;
            }

            @include respond(phone) {
                margin-top: 60px;
            }*/

            @include respond(phone-iphone) {
                margin-top: 0;
            }
        }
    }

    &-box {
        height: calc(240px + (667 - 240) * ((100vw - 375px) / (1920 - 375)));
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        &_image {
            position: absolute;
            z-index: -1;
            width: 100%;
            height: 100%;
        }

        &_title,
        &_subtitle {
            font-family: Poppins;

            font-weight: bold;
            text-transform: uppercase;
        }

        &_title {
            /*padding-top: calc(90px + (210 - 90) * ((100vw - 375px) / (1920 - 375)));*/
            padding-top: 490px;
            font-size: calc(50px + (119 - 50) * ((100vw - 375px) / (1920 - 375)));
            line-height: calc(80px + (145 - 80) * ((100vw - 375px) / (1920 - 375)));
            letter-spacing: calc(0.8px + (47.6 - 0.8) * ((100vw - 375px) / (1920 - 375)));
            color: transparent;
            -webkit-text-stroke: calc(1px + (4 - 1) * ((100vw - 375px) / (1920 - 375))) #ffffff;
            transition: all .2s ease;

            @include respond(desktop) {
                padding-top: 430px;
            }

            @include respond(tab-land) {
                padding-top: 470px;
            }

            @include respond(old-desk) {
                padding-top: 510px;
            }

            @include respond(tab-port) {
                padding-top: calc(110px + (530 - 110) * ((100vw - 375px) / (992 - 375)));
            }

           /* @include respond(tab) {
                padding-top: 430px;
            }

            @include respond(phone) {
                padding-top: 170px;
            }

            @include respond(phone-iphone) {
                padding-top: 110px;
            }*/

            @include respond(phone-small) {
                padding-top: 50px;
            }
        }

        &_subtitle {
            font-size: calc(30px + (60 - 30) * ((100vw - 375px) / (1920 - 375)));
            line-height: calc(45px + (145 - 45) * ((100vw - 375px) / (1920 - 375)));
            letter-spacing: calc(3px + (18 - 3) * ((100vw - 375px) / (1920 - 375)));
            color: $white;
        }
    }

    /* ------ Candidatos Page ------ */
    &-page {
        padding-top: 120px;
        padding-bottom: calc(152px + (266 - 152) * ((100vw - 375px) / (1920 - 375)));

        & .titles {
            width: 100%;
        }

        &_big {

            &-box {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;
                height: calc(300px + (588 - 300) * ((100vw - 375px) / (1920 - 375)));
                border-radius: calc(15px + (20 - 15) * ((100vw - 375px) / (1920 - 375)));
                padding: 0 calc(17px + (130 - 17) * ((100vw - 375px) / (1920 - 375)));
                margin-bottom: calc(25px + (40 - 25) * ((100vw - 375px) / (1920 - 375)));
            }
        }
    }

    &-interior {
        padding-top: calc(75px + (120 - 75) * ((100vw - 375px) / (1920 - 375)));
        padding-bottom: calc(152px + (135 - 152) * ((100vw - 375px) / (1920 - 375)));

        &_images {
            position: relative;
            padding-left: calc(0px + (144 - 0) * ((100vw - 375px) / (1920 - 375)));
            display: flex;

            @include respond(tab-port) {
                justify-content: center;
            }

            &-big,
            &-small {
                border-radius: 20px;
                overflow: hidden;

                & img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }

            &-big {
                width: calc(287px + (561 - 287) * ((100vw - 375px) / (1920 - 375)));
                height: calc(324px + (634 - 324) * ((100vw - 375px) / (1920 - 375)));

                @include respond(tab-port) {
                    margin: 0 0 120px -30px;
                }

                @include respond(tab) {
                    margin: 0 0 80px -30px;
                }

                @include respond(phone) {
                    margin: 0 0 20px -30px;
                }
            }

            &-small {
                position: absolute;
                bottom: calc(-70px + (-60 - -70) * ((100vw - 1200px) / (1920 - 1200)));
                right: calc(-45px + (50 - -45) * ((100vw - 1200px) / (1920 - 1200)));
                width: calc(145px + (284 - 145) * ((100vw - 375px) / (1920 - 375)));
                height: calc(165px + (323 - 165) * ((100vw - 375px) / (1920 - 375)));
                box-shadow:
                    calc(10px + (20 - 10) * ((100vw - 375px) / (1920 - 375)))
                    calc(10px + (20 - 10) * ((100vw - 375px) / (1920 - 375)))
                    calc(10px + (40 - 10) * ((100vw - 375px) / (1920 - 375))) 0 rgba(87, 64, 2, 0.25);

                @include respond(tab-land) {
                    bottom: calc(-70px + (-60 - -70) * ((100vw - 1024px) / (1200 - 1024)));
                    right: calc(-65px + (-45 - -65) * ((100vw - 1024px) / (1200 - 1024)));
                }

                @include respond(old-desk) {
                    bottom: calc(-70px + (-70 - -70) * ((100vw - 992px) / (1024 - 992)));
                    right: calc(-50px + (-65 - -50) * ((100vw - 992px) / (1024 - 992)));
                }

                @include respond(tab-port) {
                    bottom: calc(60px + (70 - 60) * ((100vw - 768px) / (992 - 768)));
                    right: calc(100px + (170 - 100) * ((100vw - 768px) / (992 - 768)));
                }

                @include respond(tab) {
                    bottom: 20px;
                    right: calc(20px + (100 - 20) * ((100vw - 480px) / (768 - 480)));
                }

                @include respond(phone) {
                    bottom: calc(-30px + (-30 - -30) * ((100vw - 320px) / (480 - 320)));
                }
            }
        }

        &_text {

            @include respond(tab) {
                margin-top: 82px;
            }

            & h2 {
                font-family: Poppins;
                font-weight: bold;
                font-size: calc(25px + (45 - 25) * ((100vw - 375px) / (1920 - 375)));
                line-height: calc(30px + (55 - 30) * ((100vw - 375px) / (1920 - 375)));
                margin-bottom: calc(10px + (25 - 10) * ((100vw - 375px) / (1920 - 375)));
            }

            & p {
                margin-bottom: 1.5rem;
            }
        }

        &_btn {
            margin: calc(50px + (60 - 50) * ((100vw - 375px) / (1920 - 375))) 0;
        }

        &_redes {
            display: flex;

            @include respond(tab) {
                flex-direction: column;
            }

            & span {
                font-family: Poppins;
                font-weight: bold;
                font-size: calc(16px + (20 - 16) * ((100vw - 375px) / (1920 - 375)));
                line-height: calc(22px + (26 - 22) * ((100vw - 375px) / (1920 - 375)));
                display: inline-flex;
                margin-right: 40px;

                @include respond(tab) {
                    margin-right: 0;
                    margin-bottom: 25px;
                }
            }

            & ul {
                list-style: none;
                margin: 0;
                padding: 0;
                display: flex;

                & li {

                    &:not(:last-child) {
                        margin-right: 35px;
                    }
                }
            }
        }
    }

    &-directorio {
        padding-top: 120px;
        padding-bottom: calc(152px + (266 - 152) * ((100vw - 375px) / (1920 - 375)));

        @include respond(tab) {
            padding-top: 0;
        }

         &_filtros {
            margin-top: calc(-23px + (-32 - -23) * ((100vw - 375px) / (1920 - 375)));

             & input {
                 border-radius: 30px;
                 font-size: 16px;
                 border: none;
                 padding: calc(10px + (20 - 10) * ((100vw - 375px) / (1920 - 375))) calc(15px + (35 - 15) * ((100vw - 375px) / (1920 - 375)));
                 background-color: $white;
                 margin-bottom: 15px;
                 width: 100%;
                 box-shadow:
                     calc(0px + (0 - 0) * ((100vw - 375px) / (1920 - 375)))
                     calc(5px + (5 - 5) * ((100vw - 375px) / (1920 - 375)))
                     calc(10px + (25 - 10) * ((100vw - 375px) / (1920 - 375))) 0 rgba(87, 64, 2, 0.25);

                 &[type=submit] {
                     font-weight: bold;
                     width: 162px;
                     background-color: $purple;
                     color: $white;
                     margin-right: 0;

                     @include respond(tab-port) {
                         width: 100%;
                     }
                 }

                 &:focus {
                     outline: none;
                 }
             }
         }

        & table {
            width: 100%;
            font-size: calc(16px + (20 - 16) * ((100vw - 375px) / (1920 - 375)));

            & thead {
                font-size: calc(14px + (16 - 14) * ((100vw - 375px) / (1920 - 375)));
                font-family: Poppins;
                font-weight: bold;
                border-bottom: 1px solid rgba(0, 0, 0, 0.3);
                margin-bottom: 20px;

                & th {
                    padding-bottom: 20px;
                }
            }

            & tbody {

                & tr {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

                    & td {
                        padding: 20px 0;
                    }
                }
            }
        }

        @include respond(tab) {

            /* Force table to not be like tables anymore */
            & table, & thead, & tbody, & th, & td, & tr {
                display: block;

                & thead {
                    border-bottom: none;
                }

                & tbody {

                    & tr {
                        padding-bottom: 20px;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                        margin-bottom: 20px;

                        & td {
                            padding: 5px !important;

                            &:before {
                                font-family: Poppins;
                                font-size: calc(14px + (16 - 14) * ((100vw - 375px) / (1920 - 375)));
                                display: block;
                                font-weight: bold;
                            }
                        }
                    }
                }
            }

            /* Hide table headers (but not display: none;, for accessibility) */
            & thead tr {
                position: absolute;
                top: -9999px;
                left: -9999px;
            }

            /*
		    Label the data
            You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
		    */
            & td:nth-of-type(1):before { content: "Nombre"; }
            & td:nth-of-type(2):before { content: "Entidad Federativa"; }
            & td:nth-of-type(3):before { content: "Distrito / Circunscripción"; }
            & td:nth-of-type(4):before { content: "Tipo de Elección"; }
        }
    }
}

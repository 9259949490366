.campana {

    &-top {
        padding-top: calc(75px + (120 - 75) * ((100vw - 375px) / (1920 - 375)));
        padding-bottom: calc(50px + (135 - 50) * ((100vw - 375px) / (1920 - 375)));

        &_box {

            &-imgs {

                &-big {
                    margin: 0 auto;
                    width: calc(265px + (561 - 265) * ((100vw - 375px) / (1920 - 375)));
                    height: calc(322px + (634 - 322) * ((100vw - 375px) / (1920 - 375)));
                    position: relative;

                    @include respond(phone) {
                        margin: 0;
                    }

                    & img {
                        border-radius: 20px;
                    }
                }

                &-small {
                    border-radius: 20px;
                    overflow: hidden;
                    position: absolute;
                    width: calc(145px + (284 - 145) * ((100vw - 375px) / (1920 - 375)));
                    height: calc(164px + (323 - 164) * ((100vw - 375px) / (1920 - 375)));
                    right: calc(-27px + (-115 - -27) * ((100vw - 375px) / (1920 - 375)));
                    bottom: calc(-45px + (-75 - -45) * ((100vw - 375px) / (1920 - 375)));
                }
            }

            &-text {
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: calc(322px + (634 - 322) * ((100vw - 375px) / (1920 - 375)));

                @include respond(tab) {
                    height: auto;
                    margin-top: 75px;
                }

                & .titles {
                    width: 100%;
                }
            }
        }
    }

    &-slider {

        &_text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: calc(470px + (800 - 470) * ((100vw - 375px) / (1920 - 375)));

            @include respond(tab-port) {
                height: calc(470px + (800 - 470) * ((100vw - 375px) / (1920 - 375))) !important;
            }
        }
    }

    &-sumate {
        height: calc(375px + (950 - 375) * ((100vw - 375px) / (1920 - 375)));

        @include respond(tab-port) {
            height: auto;
        }
    }

    &-list {

        & .swiper-pagination-bullets {
            bottom: calc(100px + (150 - 100) * ((100vw - 992px) / (1920 - 992)));
            left: calc(40px + (75 - 40) * ((100vw - 992px) / (1920 - 992)));
            width: 100%;
            text-align: left;

            @include respond(tab-port) {
                bottom: inheritx;
                top: calc(405px + (465 - 405) * ((100vw - 320px) / (991 - 320)));
            }
        }
    }

    &-galeria {
        padding: calc(120px + (260 - 120) * ((100vw - 375px) / (1920 - 375))) 0;

        &_imgs {
            margin-top: calc(25px + (60 - 25) * ((100vw - 375px) / (1920 - 375)));
            /*display: grid;
            grid-template-columns: repeat(auto-fit, minmax(300px, 450px));
            grid-gap: 1rem;*/
            display: flex;
            flex-wrap: wrap;

            & img {
                margin-right: 20px;
                margin-bottom: 20px;

                &:nth-child(4) {
                    margin-right: 0;
                }


                @include respond(phone) {
                    margin-right: 0;
                }
            }
        }
    }

    &-testimoniales {
        margin-bottom: calc(60px + (120 - 60) * ((100vw - 375px) / (1920 - 375)));

        &_box {
            border-radius: 20px;
            width: calc(255px + (398 - 255) * ((100vw - 375px) / (1920 - 375)));
            height: calc(340px + (441 - 340) * ((100vw - 375px) / (1920 - 375)));
            padding: calc(30px + (40 - 30) * ((100vw - 375px) / (1920 - 375))) ;
            box-shadow:
                calc(10px + (20 - 10) * ((100vw - 375px) / (1920 - 375)))
                calc(10px + (20 - 10) * ((100vw - 375px) / (1920 - 375)))
                calc(10px + (40 - 10) * ((100vw - 375px) / (1920 - 375))) 0 rgba(87, 64, 2, 0.25);

            /*@include respond(tab-port) {
                height: auto;
            }*/

            &-pic {
                margin: 0 auto;
                border-radius: 100px;
                width: calc(60px + (118 - 60) * ((100vw - 375px) / (1920 - 375)));
                height: calc(60px + (118 - 60) * ((100vw - 375px) / (1920 - 375)));
                overflow: hidden;
                border: 3px solid $white;
            }

            &-text {
                margin-top: 10px;

                &_name {
                    text-align: center;
                    font-family: Poppins;
                    font-weight: bold;
                    font-size: calc(16px + (20 - 16) * ((100vw - 375px) / (1920 - 375)));
                    line-height: calc(22px + (26 - 22) * ((100vw - 375px) / (1920 - 375)));
                }

                &_para {
                    text-align: center;
                    font-size: calc(16px + (20 - 16) * ((100vw - 375px) / (1920 - 375)));
                    line-height: calc(22px + (30 - 22) * ((100vw - 375px) / (1920 - 375)));
                }
            }
        }
    }
}

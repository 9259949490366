.yuawi {

    &-videos {
        margin-bottom: calc(120px + (260 - 120) * ((100vw - 375px) / (1920 - 375)));
    }

    &-interior {
        padding-top: calc(75px + (120 - 75) * ((100vw - 375px) / (1920 - 375)));
        /*padding-bottom: calc(152px + (135 - 152) * ((100vw - 375px) / (1920 - 375)));*/

        &_video {
            border-radius: calc(15px + (20 - 15) * ((100vw - 375px) / (1920 - 375)));
        }

        &_yuawi {
            height: calc(375px + (800 - 375) * ((100vw - 375px) / (1920 - 375)));

            @include respond(tab-port) {
                height: auto;
            }

            & img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        &_flex {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: calc(375px + (800 - 375) * ((100vw - 375px) / (1920 - 375)));

            @include respond(tab) {
                padding: 40px 0 10px;
                height: auto;
            }
        }

        &_images {
            position: relative;
            padding-left: calc(0px + (144 - 0) * ((100vw - 375px) / (1920 - 375)));

            &-big,
            &-small {
                border-radius: 20px;
                overflow: hidden;

                & img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }

            &-big {
                width: calc(287px + (561 - 287) * ((100vw - 375px) / (1920 - 375)));
                height: calc(324px + (634 - 324) * ((100vw - 375px) / (1920 - 375)));
            }

            &-small {
                position: absolute;
                bottom: calc(-40px + (-60 - -40) * ((100vw - 375px) / (1920 - 375)));
                right: calc(-16px + (50 - -16) * ((100vw - 375px) / (1920 - 375)));
                width: calc(145px + (284 - 145) * ((100vw - 375px) / (1920 - 375)));
                height: calc(165px + (323 - 165) * ((100vw - 375px) / (1920 - 375)));
            }
        }
    }
}

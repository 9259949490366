/*------------- General --------------*/
body{
    font-family: Roboto;
    font-size: calc(16px + (20 - 16) * ((100vw - 375px) / (1920 - 375)));
    line-height: calc(22px + (30 - 22) * ((100vw - 375px) / (1920 - 375)));
}
.error_colors{
    font-weight: bold;
    color: $red;
}

.note {
    font-size: 12px;
    font-style: italic;
    display: block;
}

/*------------- Colors Texts --------------*/
.c-white {
    color: $white !important;
}


/*------------- Colors Backgrounds --------------*/
.b-purple {
    background-color: $purple;
}

.b-pumpkin-orange {
    background-color: $pumpkin-orange;
}

.b-whitefa {
    background-color: $white-fa;
}

/*--------------- Titles & Subtitles ----------------*/
.titles {
    font-family: Poppins;
    font-weight: bold;
    font-size: calc(25px + (45 - 25) * ((100vw - 375px) / (1920 - 375)));
    line-height: calc(30px + (55 - 30) * ((100vw - 375px) / (1920 - 375)));
    color: $black;
    width: calc(300px + (675 - 300) * ((100vw - 375px) / (1920 - 375)));

    &-30 {
        font-size: calc(20px + (30 - 20) * ((100vw - 375px) / (1920 - 375)));
        line-height: calc(25px + (45 - 25) * ((100vw - 375px) / (1920 - 375)));
    }
}

.subtitles {
    font-size: calc(12px + (16 - 12) * ((100vw - 375px) / (1920 - 375)));
    line-height: calc(16px + (21 - 16) * ((100vw - 375px) / (1920 - 375)));
    letter-spacing: calc(1.56px + (2.08 - 1.56) * ((100vw - 375px) / (1920 - 375)));
    color: $black;
    text-transform: uppercase;
    margin-bottom: calc(5px + (10 - 5) * ((100vw - 375px) / (1920 - 375)));
}

.description {
    color: $black;
    font-size: calc(16px + (20 - 16) * ((100vw - 375px) / (1920 - 375)));
    line-height: calc(22px + (30 - 22) * ((100vw - 375px) / (1920 - 375)));
    margin-top: calc(15px + (25 - 15) * ((100vw - 375px) / (1920 - 375)));
    margin-bottom: calc(30px + (40 - 30) * ((100vw - 375px) / (1920 - 375)));

}

.optional {
    font-weight: 500;
    font-style: italic;
    text-transform: uppercase;
    color: rgba(0,0,0,0.5);
    font-size: calc(12px + (14 - 12) * ((100vw - 375px) / (1920 - 375)));
    line-height: calc(16px + (19 - 16) * ((100vw - 375px) / (1920 - 375)));
    letter-spacing: calc(1.56px + (1.82 - 1.56) * ((100vw - 375px) / (1920 - 375)));
    margin-top: calc(15px + (25 - 15) * ((100vw - 375px) / (1920 - 375)));
    margin-bottom: calc(30px + (60 - 30) * ((100vw - 375px) / (1920 - 375)));
}


/*--------------- Background Dots ----------------*/
.b-dots {

    &_intern {
        background-image: url("/images/puntos-amarillos.svg");
        background-repeat: no-repeat;
        background-position-x: left;
        background-position-y: 203px;

        @include respond(tab-port) {
            background-image: none;
        }
    }

    &_carousel {
        background-image: url("/images/six-points.svg");
        background-repeat: no-repeat;
        background-position-x: 1330px;
        background-position-y: 10px;

        @include respond(tab) {
            background-image: none;
        }
    }

    &_purple {

        &-6 {
            background-image: url("/images/dots/puntos-morados-1.svg");
            background-repeat: no-repeat;
            background-position-x: 350px;
            background-position-y: 100px;

            @include respond(tab-port) {
                background-image: none;
            }
        }
    }

    &_orange {

        &-middletop {
            background-image: url("/images/dots/puntos-amarillos-12.svg");
            background-repeat: no-repeat;
            background-position-x: left;
            background-position-y: -100px;

            @include respond(tab) {
                background-image: none;
            }
        }
    }
}

/*--------------- Dots Imagenes Position Absolute ----------------*/
.dots {

    &-orange {

        &_middletop {
            position: absolute;
            left: 0;
            top: -110px;

            @include respond(tab-port) {
                display: none;
            }
        }
    }
}

/*--------------- Margins ----------------*/
.ma-top260 {
    margin-top: calc(120px + (260 - 120) * ((100vw - 375px) / (1920 - 375)));
}

.ma-top250lg {
    margin-top: calc(120px + (250 - 120) * ((100vw - 375px) / (1920 - 375)));

    @include respond(tab) {
        margin-top: 0;
    }
}

/*--------------- Border Radius ----------------*/
.radius10 {
    border-radius: 10px;
}
.radius25 {
    border-radius: calc(15px + (25 - 15) * ((100vw - 375px) / (1920 - 375)));
}
.radius30 {
    border-radius: calc(15px + (30 - 15) * ((100vw - 375px) / (1920 - 375)));
}

/*--------------- IMG Options ----------------*/
.obj-fit {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


/*--------------- Paddings ----------------*/
.space-topbottom_130 {
    padding: calc(60px + (130 - 60) * ((100vw - 375px) / (1920 - 375))) 0;
}

.space-bottom130 {
    padding-bottom: calc(50px + (130 - 50) * ((100vw - 375px) / (1920 - 375)));
}


/*--------------- Paragraphs Default ----------------*/
p {
    font-size: calc(16px + (20 - 16) * ((100vw - 375px) / (1920 - 375)));
    line-height: calc(22px + (30 - 22) * ((100vw - 375px) / (1920 - 375)));
}

/*--------------- Boxes Shadows ----------------*/
.box-shadow20 {
    box-shadow:
        calc(10px + (20 - 10) * ((100vw - 375px) / (1920 - 375)))
        calc(10px + (20 - 10) * ((100vw - 375px) / (1920 - 375)))
        calc(10px + (40 - 10) * ((100vw - 375px) / (1920 - 375))) 0 rgba(87, 64, 2, 0.25);
}


.d-contents {
    display: contents;
}


/* ---------------- Half Columns ----------------- */

.col-lg-halftree {
    @include col-half(3);
    flex: 0 0 25%;
}

.col-lg-halftwo {
    @include col-half(2);
}

/* ---------------- Box Component ----------------- */
.box {
    &-radius {
        border-radius: 15px;
        overflow: hidden;
        position: relative;
        height: 311px;

        @include respond('desktop') {
            height: 230px;
            font-size: 16px;
        }

        @include respond('tab-land') {
            height: 200px;
            font-size: 14px;
        }

        @include respond('tab-port') {
            font-size: 16px;
        }

        @include respond('phone') {
            height: auto;
            font-size: inherit;
        }
        &.pos-afil{
            bottom: 0;
            &:hover{
                bottom: 4px;
            }
        }

        &:hover {
            & .cards-box-people_arrow {
                opacity: 1;
            }

            & .box-radius_img-descri {
                height: 170px;

                @include respond('desktop') {
                    height: 100px;
                }

                @include respond('old-desk') {
                    height: 90px;
                }

                @include respond('tab-port') {
                    height: 100px;
                }

                @include respond('tab') {
                    height: 90px;
                }

                @include respond('phone') {
                    height: auto;
                }
            }

            & .box-radius_td {
                padding: 15px;
                height: auto;
                display: block;
            }

            & .box-radius_descri {
                display: block;
                line-height: 22px;
                margin-top: 5px;

                @include respond('old-desk') {
                    line-height: 1.2;
                }

                @include respond('phone') {
                    line-height: 22px;
                }
            }
        }

        &_img,
        &_img-descri {
            height: 243px;
            transition: all .2s;

            @include respond('desktop') {
                height: 170px;
            }

            @include respond('old-desk') {
                height: 130px;
            }

            @include respond('tab') {
                height: 90px;
            }

            @include respond('phone') {
                height: auto;
            }
        }

        & .cards-box-people_arrow {

            @include respond('phone') {
                opacity: 1;
            }
        }

        &_title,
        &_td {
            height: 68px;
            display: flex;
            justify-content: center;
            align-items: center;

            @include respond('tab') {
                display: block;
                padding: 15px;
                height: auto;
            }
        }


        &_descri {
            display: none;
            font-weight: normal;

            @include respond('tab-land') {
                line-height: 1.2;
            }

            @include respond('tab') {
                display: block;
                margin-top: 5px;
            }

            @include respond('phone') {
                line-height: 22px;
            }
        }
    }
}


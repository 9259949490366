.footer{
    background-color: $black;

    & p{
        font-size: calc(12px + (14 - 11) * ((100vw - 300px) / (1920 - 300)));
    }
ul a{
    color: $white;
    & li{
        color: $white;
        opacity: 0.7;
        font-weight: normal;
        list-style: none;
        font-size: calc(12px + (14 - 11) * ((100vw - 300px) / (1920 - 300)));
        &:hover{
            text-decoration: none;
            color: $white;
            opacity: 1;
        }
    }
    &:hover{
        color: $white;
    }
}
}
i{
    color: $white;
    font-size: calc(12px + (18 - 11) * ((100vw - 300px) / (1920 - 300)));
}

.text-white-0{
    color: $white;
}
.pol-pri{
    color:  $white;
    background-color: $greyish-brown;
    font-weight: bold;
    & a{
        font-size: calc(12px + (14 - 11) * ((100vw - 300px) / (1920 - 300)));
        color: $white;
    }
}





.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 56;
    display: flex;
    opacity: 0;
    justify-content: center;
    align-items: center;
    transition: all .10s;

    &_box {
        background-color: $white;
        width: calc(270px + (535 - 270) * ((100vw - 375px) / (1920 - 375)));
        height: 565px;
        border-radius: calc(10px + (20 - 10) * ((100vw - 375px) / (1920 - 375)));
        box-shadow: 0 20px 40px 0 rgba(87, 64, 2, 0.15);
        padding: 15px;
        transition: all .2s;

        @include respond(tab) {
            height: auto;
        }

        &-image {
            width: 100%;
            height: 168px;
            -webkit-border-top-left-radius: 20px;
            -webkit-border-top-right-radius: 20px;
            -moz-border-radius-topleft: 20px;
            -moz-border-radius-topright: 20px;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            overflow: hidden;
            background-image: url("/images/img-fondo-pop-up.jpg");
            background-repeat: no-repeat;

            &_logo {
                background-color: $white;
                border-radius: 50%;
                /*width: calc(80px + (160 - 80) * ((100vw - 375px) / (1920 - 375)));
                height: calc(80px + (160 - 80) * ((100vw - 375px) / (1920 - 375)));*/
                width: 160px;
                height: 160px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                transform: translate(-50%, -50%);
                top: -15%;
                left: 50%;
                -webkit-box-shadow: 3px 5px 22px 0 rgba(0,0,0,0.2);
                -moz-box-shadow: 3px 5px 22px 0 rgba(0,0,0,0.2);
                box-shadow: 3px 5px 22px 0 rgba(0,0,0,0.2);
            }
        }

        &-cerrar {
            position: absolute;
        }

        &-text {
            position: relative;
            padding-top: 62px;
            text-align: center;

            & p {
                font-size: 20px;
                line-height: 29px;
            }

            & h2 {
                color: $pumpkin-orange;
                font-size: calc(28px + (38 - 28) * ((100vw - 375px) / (1920 - 375)));
                font-family: "Poppins", Arial, Helvetica, sans-serif;
                font-weight: bold;
                margin-bottom: 15px;
            }

            & button {
                background-color: $purple;
                color: $white;
                text-transform: uppercase;
                font-size: 14px;
                font-weight: bold;
                line-height: 3.86;
                letter-spacing: calc(1.2px + (1.82 - 1.2) * ((100vw - 375px) / (1920 - 375)));
                width: calc(200px + (294 - 200) * ((100vw - 375px) / (1920 - 375)));
                height: 55px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: calc(20px + (54 - 20) * ((100vw - 375px) / (1920 - 375))) auto 0;
                transition: all .2s;

                &:hover {
                    background-color: $pumpkin-orange;
                }
            }
        }
    }
}

.state {

    &-coordinator {
        background-image: url("/images/puntos-amarillos-home.svg");
        background-position-y: 122px;
        background-position-x: left;
        background-repeat: no-repeat;

        @include respond(tab) {
            background-image: none;
        }

        &_image {
            overflow: hidden;
            border-radius: 20px;
            width: calc(190px + (262 - 190) * ((100vw - 320px) / (1920 - 320)));
            height: 322px;
            box-shadow: 20px 20px 40px 0 rgba(87, 64, 2, 0.25);

            @include respond(tab) {
                width: 100%;
                margin: 0 auto;
            }

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        &_name {
            font-size: 16px;
        }

        &_social {

            ul {
                margin: 0;
                padding: 0;
            }

            li {
                list-style: none;
                display: inline-flex;

                &:not(:last-child) {
                    margin-right: 30px;
                }

                i {
                    color: $black;
                    font-size: 13px;
                }
            }
        }
    }
}

.pagination {

    @include respond(tab) {
        li.page-item {
            display: none;
        }

        .page-item:first-child,
        .page-item:last-child,
        .page-item.active {

            display: block;
        }
    }

    & .page-item.disabled .page-link {
        background: transparent;
    }

    & li {

        &.active {
            & .page-link {
                color: $white;
                background-color: $pumpkin-orange;
            }
        }

        & .page-link {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 12px;
            margin: 0 5px;
            font-size: 18px;
            font-weight: bold;
            line-height: 1.25;
            color: $black;
            border: none;
            height: 35px;
            border-radius: 10px;
            background: transparent;

            &:hover {
                color: $white;
                background-color: $pumpkin-orange;
            }
        }
    }

    &-candidatos {
        display: flex;
        justify-content: center;
        margin-top: 90px;

        & a {
            margin: 0 5px;
            width: auto;
            height: 35px;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $black;
            font-size: 18px;
            font-weight: bold;
            line-height: 1.56;

            &.link_inactive {
                padding: 0 12px;
            }

            &.link_inactive:hover,
            &.link_active {
                background-color: $pumpkin-orange;
                color: $white;
                padding: 0 12px;
            }

            &.link_active {
                cursor: grab;
                pointer-events: none;
            }
        }

        & span {
            margin: 0 12px;
            font-size: 18px;
            font-weight: bold;
            line-height: 1.56;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 200px;
            letter-spacing: 1.82px;
            color: rgba(0, 0, 0, 0.4);
            cursor: grab;

            & i {
                color: rgba(0, 0, 0, 0.4);

                &.left {
                    padding-left: 12px;
                }

                &.right {
                    padding-right: 12px;
                }
            }

            & a {
                color: rgba(0, 0, 0, 0.4);
                padding: 0 22px;

                &:hover {
                    color: $black;
                    background-color: rgba(111, 31, 180, 0.08);

                    & i {
                        color: $black;
                    }
                }
            }
        }
    }
}

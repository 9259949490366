.podcast {
    padding-top: calc(100px + (160 - 100) * ((100vw - 375px) / (1920 - 375)));
    padding-bottom: calc(40px + (80 - 1240) * ((100vw - 375px) / (1920 - 375)));
}
.txt-bold{font-weight: bold;}
.txt-14{font-size: calc(14px + (14 - 14) * ((100vw - 375px) / (1920 - 375)));}
.txt-16{font-size: calc(14px + (16 - 14) * ((100vw - 375px) / (1920 - 375)));}
.txt-20{font-size: calc(16px + (20 - 16) * ((100vw - 375px) / (1920 - 375)))!important;}
.txt-24{font-size: calc(20px + (24 - 20) * ((100vw - 375px) / (1920 - 375)))!important;}
.purplre-square-points {
  right: 14%;
  margin-top: -90px;
  position: absolute;
  z-index: 0;
  @include respond(desktop){
    right: -1%;
  }
  @include respond(old-desk){
    right: 10%;
    margin-top: 0%;
  }
  @include respond(tab-port ){
    right: -6%;
    margin-top: -45px;
  }
  @include respond(tab ){
      right: 5%;
      margin-top: 5px;
  }
}
.portada{
  margin-top: -35px;
  margin-left: -14px;
  position: relative;
  z-index: 2;
  @include respond(old-desk){
    margin-top: 59px;
    margin-left: 50px;
  }
  @include respond(tab-port ){
    margin-top: 5px;
    margin-left: 20px;
  }
  @include respond(tab ){
    margin-top: 50px;
    margin-left: 12px;
  }
}

.play{
  margin-top: -209px;
  left: -255px;
  position: relative;
  z-index: 3;
  @include respond(tab-port ){
    margin-top: -83px;
    margin-left: -115px;
    left: 0px;
  }
  @include respond(tab ){
    margin-top: -130px;
    margin-left: -255px;
    left: 0px;
  }
  @include respond(phone){
    margin-top: 0px;
    margin-left: 17px;
    left: 0px;
  }
}

.redes {
    text-align: center;
    color: $white;
    padding: calc(100px + (85 - 100) * ((100vw - 375px) / (1920 - 375))) 0;
    background: linear-gradient(129deg, rgba(253,131,36,1) 31%, rgba(255,78,0,1) 104%);
    /*background: linear-gradient(307deg, rgba(255,78,0,1) 31%, rgba(253,131,36,1) 104%);*/
    &_nobis{
        background: $white-fa;
    }


    &-big {

        &_text {
            font-family: Poppins;
            font-weight: bold;
            font-size: calc(50px + (119 - 50) * ((100vw - 375px) / (1920 - 375)));
            line-height: 145px;
            letter-spacing: calc(0.5px + (48 - 0.5) * ((100vw - 375px) / (1920 - 375)));
            color: transparent;
            -webkit-text-stroke: calc(1.7px + (4 - 1.7) * ((100vw - 375px) / (1920 - 375))) $white;
        }
    }

    &-sub {

        &_text {
            margin-top: calc(15px + (35 - 15) * ((100vw - 375px) / (1920 - 375)));
            margin-bottom: calc(60px + (90 - 60) * ((100vw - 375px) / (1920 - 375)));
            font-family: Poppins;
            font-weight: bold;
            font-size: calc(25px + (45 - 25) * ((100vw - 375px) / (1920 - 375)));
            line-height: calc(30px + (55 - 30) * ((100vw - 375px) / (1920 - 375)));
            color: $white;
        }
    }

    &-list {
        & ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            justify-content: center;

            & li {
                padding: 0 calc(15px + (37 - 15) * ((100vw - 320px) / (1920 - 320)));
                &.nobis{
                    padding: 0 calc(15px + (19 - 15) * ((100vw - 320px) / (1920 - 320)));
                }

                & i {
                    font-size: calc(21px + (30 - 21) * ((100vw - 375px) / (1920 - 375)));
                    &.nobis{
                            font-size: calc(16px + (20 - 16) * ((100vw - 375px) / (1920 - 375)));
                        }
                }
            }
        }
    }
}

.somos {
    padding-top: calc(190px + (172 - 190) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: calc(60px + (150 - 60) * ((100vw - 320px) / (1920 - 320)));

    @include respond(tab){
        padding-top: calc(90px + (130 - 90) * ((100vw - 375px) / (1920 - 375)));
    }

    &-dot {
        padding-top: calc(190px + (172 - 190) * ((100vw - 320px) / (1920 - 320)));
        padding-bottom: calc(60px + (150 - 60) * ((100vw - 320px) / (1920 - 320)));
        background-image: url("/images/puntos-amarillos-home.svg");
        background-position-y: 122px;
        background-position-x: left;
        background-repeat: no-repeat;

        @include respond(tab) {
            background-image: none;
        }
    }

    & a {
        color: $black;

        :hover {
            text-decoration: none;
        }
    }

    &-list {
        background-image: url("/images/six-points.svg");
        background-repeat: no-repeat;
        background-position-x: 1330px;
        background-position-y: 10px;

        @include respond(tab) {
            background-image: none;
        }
    }
}

.afiliados{
    & .size{
    font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1920 - 300)));
    }
    & .bold{
        font-weight: bold;
        &__italic {
            font-style: italic;
            &__orage{
                color:$pumpkin-orange;;
            }
        }
    }

    .purple{
        text-decoration-line: underline;
        font-weight: normal;
        color: $purple;
    }
}

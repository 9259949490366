.notification {

    &-box {
        border-radius: 15px;
        box-shadow: 20px 15px 40px 0 rgba(87, 64, 2, 0.08);
        background-color: #ffffff;
        padding: 25px;
        height: 382px;

        &_state {
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 0.24px;
            text-align: right;
            color: $greyish-brown;
            opacity: 0.3;
            text-transform: uppercase;
            margin-bottom: 21px;
        }

        &_icon {
            margin-bottom: 25px;
        }

        &_title {
            font-size: 18px;
            line-height: 24px;
            word-break: break-word;
        }
    }
}

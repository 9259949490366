/*section acerca de - nobis*/

.titles2 {
    font-family: Poppins;
    font-weight: bold;
    font-size: calc(25px + (45 - 25) * ((100vw - 375px) / (1920 - 375)));
    line-height: calc(30px + (55 - 30) * ((100vw - 375px) / (1920 - 375)));
    color: $black;

    &-30 {
        font-size: calc(20px + (30 - 20) * ((100vw - 375px) / (1920 - 375)));
        line-height: calc(25px + (45 - 25) * ((100vw - 375px) / (1920 - 375)));
    }
}

.description-size{
    font-size: calc(16px + (20 - 16) * ((100vw - 375px) / (1920 - 375)));
    line-height: calc(22px + (30 - 22) * ((100vw - 375px) / (1920 - 375)));
    margin-top: calc(15px + (25 - 15) * ((100vw - 375px) / (1920 - 375)));

}



.puntos-morados{
    position: absolute;
    z-index: -1;
    margin-left: 40%;
    margin-top: -4%;
}

.img-separadas img{
    float:left;
    width:50%;
}

.ptb{
    padding: 7% 15px;
    @include respond(phone) {
        padding: 15% 15px;
    }
}

/*estilo para link de link/boton con efecto*/
.btns-seeall.btons-orange::before{
    background-color: #f6993f !important;
    font-size: 14px !important;
}

/*section revistas*/
.revista{
    width: 80%;
}

.text18{font-size: calc(16px + (18 - 16) * ((100vw - 375px) / (1920 - 375)));}
.text16{font-size: calc(14px + (16 - 14) * ((100vw - 375px) / (1920 - 375)));}
.text14{font-size: 14px;}
.text12{font-size: 12px;}

.fa-eye{color: #000;}

.btndescarga{
    position: absolute;
    z-index: 2;
    right: 10%;
    top: 5%;
    display: none;
    @include respond(tab-port) {
        display: block;
    }
}

.revistablock:hover .btndescarga{
    display: block;
}

.roundimg, iframe{
    -webkit-border-radius: 20px 20px 20px 20px;
    border-radius: 20px 20px 20px 20px;
}

.round30{
    -webkit-border-radius: 30px 30px 30px 30px;
    border-radius: 30px 30px 30px 30px;
}

.portada-shadow{
    -webkit-box-shadow: 15px 15px 10px 15px rgba(87,64,2,.02);
    box-shadow: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320))) calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320))) calc(10px + (40 - 10) * ((100vw - 320px) / (1920 - 320))) 0 rgba(87, 64, 2, 0.25)
}

/*clase para impedir que el contenedor cresca mas alla de lo indicado*/
.maxsize{
    max-width: 1400px;
    margin: 0 auto;
}


/*section articulos*/
.news-box_tag.art, .invt{
    font-weight: normal !important;
    font-family: Roboto;
    & .fa-eye{
        font-size: 12px;
    }
}

.news-box_tag2 {
    position: absolute;
    z-index: 1;
    top: 57%;
    left: 8px;
    min-width: 164px;
    height: 31px;
    padding: 8px 22px 7px;
    border-radius: 15px;
    background-color: rgba(250, 250, 250, 0.8);
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.24px;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    font-family: Roboto;
}

.news-box_arrow{
    opacity: 0;
    @include respond(tab-port) {
        opacity: 1;
    }
}

.news-box_text.art {
    font-size: calc(14px + (18 - 14) * ((100vw - 375px) / (1920 - 375)));
    font-weight: normal;
    font-family: Roboto;
}

.select-articles {
    border: none;
    padding: 10px;
    border-radius: calc(15px + (30 - 15) * ((100vw - 375px) / (1920 - 375)));
    box-shadow: 0 5px 15px 0 rgb(29 75 139 / 16%);
    background-color: $white;
    margin-right: 15px;
    height: 60px;
    width: 300px;
    display: inline-flex;

    & select {
        border: none;
        color: #c7c7c7;
        width: 100%;
        background-color: transparent;

        &:focus {
            outline: none;
        }
    }


}


/*interno articulos*/
.prensa-interno_tag.alto{
    height:auto !important;
}


.img-txt-autor{
    color:$white;
    background-color: $pumpkin-orange;
    font-style: italic;
    position: absolute;
    right: 3%;
    bottom: 4%;
    padding: 3px 15px;
    z-index: 2;
    @include respond(tab-port) {
        display:none !important;
    }
}


/*colores originales para compartir redes sociales*/
.original .fa-facebook-f{color:$french-blue !important;}
.original .fa-twitter{color:$dodger-blue;}
.original .fa-whatsapp{color:$verdewp;}


/*estilos de compartir con efecto hover*/
.prensa-hover_social_hovers{
    padding: 8px 3px 2px 3px;
    width: 55px;
    text-align: center;
    position: absolute;
    z-index: 5;
    &:hover{
        background-color: $mango-two;
    }
    @include respond(tab-port) {
        /*background-color: $mango-two;*/
    }
    & a{
        float:none;
        margin-top: 10px;
    }
    & i:hover{
        -webkit-border-radius: 20px 20px 20px 20px;
        border-radius: 20px 20px 20px 20px;
        background-color: $pumpkin-orange;
    }
    & i{
        padding:12px;
        font-size: 20px;
    }
    & #bshare:hover .elementshare{ 
        visibility: visible !important;
        opacity: 1 !important;
    }
    & .elementshare{
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 0.5s linear;
        @include respond(tab-port) {
           /* visibility: visible;
        opacity: 1;*/
        }
    }
}

.prensa-interno_social-der{
    & i{
        font-size: 20px;
        &:hover{
            transform: translateY(-15%);
        }
    }
}


/*fondo documental*/

/*cortometrajes - interior*/
.shadows{
    box-shadow: 20px 15px 40px 0 rgba(87, 64, 2, 0.1);
}

.prensa-interno_social-der a{
    margin: 10px;
}


/*tareas editoriales*/
.pte{
    padding: 7% 15px 0px;
}

.pbe{
    padding: 0px 15px 7%;
}

.t20 {
    font-size: calc(18px + (20 - 18) * ((100vw - 375px) / (1920 - 375)));
    line-height: calc(25px + (35 - 25) * ((100vw - 375px) / (1920 - 375)));
}
.listaf{

    & ul{
        list-style: outside;
        & li{
            margin-bottom: 2%;
            @include respond(phone) {
                margin-bottom: 5%;
            }
            & a{
                font-weight: normal;
                font-size: calc(14px + (18 - 14) * ((100vw - 375px) / (1920 - 375)));
            }
        }
    }
}
#scroll{
    &.nav-tabs .nav-link
    {
        display: block;
        position: relative;
        &.active, .show .nav-link{
            color: #000000;
            font-weight: bold;
            background-color:transparent !important;
            border: 0px;  
            &::after
            {
                content: "";
                margin: 0 auto;
                width: 40px;
                border-bottom: 3px solid #6e29b1;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
            }      
        }
        &:hover, :focus
            {border-color: transparent;}
        &:hover::after, 
         :focus::after{
            content: "";
            margin: 0 auto;
            width: 40px;
            border-bottom: 3px solid #6e29b1;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }
        
    }   
   
}

/*investigaciones*/
.txt-bold{
    font-weight: bold;
}

.topimg{
    position:relative;
}

.btndescarga.invs{
    right: 4%;
    top: 17px;
}

.tag-inv {
    position: absolute;
    z-index: 1;
    top: 92%;
    left: 8px;
    min-width: 164px;
    height: 31px;
    padding: 8px 22px 7px;
    border-radius: 15px;
    background-color: rgba(250, 250, 250, 0.8);
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.24px;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    font-family: Roboto;
    transition: all ease .5s;

    @include respond(tab-port) {
        display: none;
    }
}

.topimg{
    height: calc(235px + (692 - 235) * ((100vw - 375px) / (1920 - 375)));
    width: 100%;
    overflow: hidden;
    transition: all .2s ease;
    background-color: #e0dfdd;
    border-radius: 20px;

    @include respond(desktop) {
        height: calc(235px + (845 - 235) * ((100vw - 375px) / (1920 - 375)));
        overflow: hidden;
    }
    @include respond(tab-land) {
        height: calc(235px + (890 - 235) * ((100vw - 375px) / (1920 - 375)));
        overflow: hidden;
    }
    @include respond(old-desk) {
        height: calc(235px + (870 - 235) * ((100vw - 375px) / (1920 - 375)));
        overflow: hidden;
    }
    @include respond(tab-port) {
        height: auto;
        overflow: visible;
    }
    @include respond(phone) {
        height: auto;
        overflow: visible;
    }
    @include respond(phone-iphone) {
        height: auto;
        overflow: visible;
    }
    @include respond(phone-small) {
        height: auto;
        overflow: visible;
    }
    & .imgsizehover{
        height: calc(235px + (542 - 235) * ((100vw - 375px) / (1920 - 375)));
        background-repeat: no-repeat;
        background-size: contain;
        background-position: top center;
        position: relative;
        transition: all ease .5s;

        @include respond(desktop) {
            height: calc(235px + (630 - 235) * ((100vw - 375px) / (1920 - 375)));
        }
        @include respond(tab-land) {
            height: calc(235px + (575 - 235) * ((100vw - 375px) / (1920 - 375)));
        }
        @include respond(old-desk) {
            height: calc(235px + (520 - 235) * ((100vw - 375px) / (1920 - 375)));
        }
    }
    &:hover .imgsizehover {
        height: 235px;
    }
    &:hover .btndescarga{
        display:block;
    }
    &:hover .tag-inv {
        display: none;
    }
    &:hover .bottxt
    {
        transform: translateY(-65px);
        @include respond(desktop) {
            transform: translateY(-80px);
        }
        @include respond(tab-land) {
            transform: translateY(-170px);
        }
        @include respond(old-desk) {
            transform: translateY(-180px);
        }
        @include respond(tab-port) {
            transform: translateY(0px);
        }
        @include respond(phone) {
            transform: translateY(0px);
        }
        @include respond(phone-iphone) {
            transform: translateY(0px);
        }
        @include respond(phone-small) {
            transform: translateY(0px);
        }
    }
    & .bottxt{
        bottom: 0;
        width: 100%;
        height: 100%;
        transition: all 0.3s;
        border-radius: 0px 0px 20px 20px;
        @include respond(tab-port) {
            transform: translateY(0px);
        }
        @include respond(phone) {
            transform: translateY(0px);
        }
        @include respond(phone-iphone) {
            transform: translateY(0px);
        }
        @include respond(phone-small) {
            transform: translateY(0px);
        }

    }
    & .titinve{
        height: 132px;
        display: flex;
        align-items: center;

        @include respond(tab-port) {
            height: auto;
        }
    }
    .sharehover{
        display:none;
        right: 15%;
        top: 0px;
        position:absolute;
        z-index: 6;
        @include respond(tab-port) {
            display:block;
        }
    }
    &:hover .sharehover{
        display:block;
    }
}
.nobis-form{
    ::placeholder {
        color: $black-two;
    }
    input,textarea{
        box-shadow: 20px 20px 40px 0 rgba(0, 0, 0, 0.05);
    }
}

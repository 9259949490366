.mpmovimientos{
    background-color: $white-fa;
    .movimiento{
        font-size: calc(18px + (20 - 18) * ((100vw - 375px) / (1920 - 375)));
    }
    li p{
        font-size: calc(18px + (20 - 18) * ((100vw - 375px) / (1920 - 375)));
        padding: 0 10px;
    }
    h2{
        font-size: calc(35px + (45 - 35) * ((100vw - 375px) / (1920 - 375)));
    }
    h3{
        font-size: calc(25px + (30 - 25) * ((100vw - 375px) / (1920 - 375)));
    }
    span{
        font-weight: bold;
        color: #fd8324;
    }
    .circle{
        width: 25px;
        height: 25px;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        background: #fd8324;
        color: $white;
        line-height: 25px;
        text-align: center;
        vertical-align: middle;
        font-weight: bold;
    }
    a{
        font-weight: normal;
        color: $black;
        padding-top: 50px;
        &:hover{
            padding-top: 45px;
            transition-delay: 0.5s;
        }
    }

    li::marker {
        color: $purple;
    }
    .card-item{
        height: calc(235px + (330 - 235) * ((100vw - 300px) / (1920 - 300)));
        width: calc(300px + (408 - 300) * ((100vw - 300px) / (1920 - 300)));
        .icon-flecha{
            display: none;
        }
        .card-image{
            background: {
                size: cover;
                position: top center;

            }
            height: 260px;
            transition: all 0.3s;
        }
        .card-info{
            bottom: 0;
            width: 100%;
            transition: all 0.3s;
            p{
                height: 100px;
                &.card-description{
                    font-size: 14px;
                    line-height: 1.1rem;
                }
            }
        }

        &:hover{
            .card-image{
                height: calc(150px + (190 - 150) * ((100vw - 300px) / (1920 - 300)));
            }
            .card-info{
                padding-top: 5px !important;
                .card-title{
                    text-align: left;
                    margin-bottom: 5px;
                }
                .card-description{
                    display: block!important;
                }
            }
            .icon-flecha{
                display: inline;
                background-color: #fd8324;
                padding: 5px 10px;
                border-radius: 25%;
                margin-left: calc(250px + (360 - 250) * ((100vw - 300px) / (1920 - 300)));
            }
        }
    }
}
.conten-center{
    justify-content: center;
}
.margi{
    margin:0 calc(-5px - (-35 + -5) * ((100vw - 300px) / (1920 - 1400)));
    padding: 0 200px;
}

@media (max-width: 414px) {
    .mpmovimientos{
        .card-item{
            height: calc(340px + (340 + 340) * ((100vw - 300px) / (1920 - 300)));

            .icon-flecha{
                    display: inline;
                    background-color: #fd8324;
                    padding: 5px 10px;
                    border-radius: 25%;
                    margin-left: calc(250px + (360 - 250) * ((100vw - 300px) / (1920 - 300)));
            }
        }
    }

    .margi{
        margin:0 0 !important;
        padding: 40px !important;
    }
}
@media (min-width: 1024px) and (max-width: 1220px) {
    .mpmovimientos{
        .card-item{
            height: calc(260px + (330 - 260) * ((100vw - 300px) / (1920 - 300)));
            width: calc(300px + (370 - 300) * ((100vw - 300px) / (1920 - 300)));
            .card-image{
                height: 210px;
            }

            &:hover{
                .card-image{
                    height: 164px;
                }
                .icon-flecha{
                    margin-left: calc(250px + (330 - 250) * ((100vw - 300px) / (1920 - 300)));
                }
            }
        }
    }
    .margi{
        margin:0 calc(7px - (-45 + 7) * ((100vw - 300px) / (1440 - 1024)));
    }
}
@media (min-width: 1280px) and (max-width: 1439px) {
    .mpmovimientos{
        .card-item{
            height: calc(300px + (330 - 300) * ((100vw - 300px) / (1920 - 300)));
            width: calc(300px + (370 - 300) * ((100vw - 300px) / (1920 - 300)));
        }
    }
}
@media (min-width: 1440px) and (max-width: 1440px) {
    .mpmovimientos{
        .card-item{
            width: calc(300px + (370 - 300) * ((100vw - 300px) / (1920 - 300)));
            .card-image {
                height: 220px;
            }
            &:hover {
                .card-image {
                    height: 184px;
                }

                .icon-flecha {
                    margin-left: calc(250px + (300 - 250) * ((100vw - 300px) / (1440 - 300)));
                }
            }
        }
    }
    .margi{
        margin:0 calc(2px - (-40 + 2) * ((100vw - 300px) / (1440 - 1024)));
    }
}

.mpsitio{
    background-color: $white-fa;
    p{
        font-size: calc(16px + (18 - 16) * ((100vw - 375px) / (1920 - 375)));
    }

    a{
        font-weight: normal;
        color: $black;
        padding-top: 60px;
        &:hover{

                .mpsitio-box-icon-flecha {
                    display: inline;
                    background-color: #fd8324;
                    padding: 5px 10px;
                    border-radius: 25%;
                    margin-left: calc(250px + (280 - 250) * ((100vw - 300px) / (1920 - 300)));
                }
            padding-top: 45px;
            transition: all .2s ease;
        }
    }

        &-box {
            height: calc(300px + (382 - 300) * ((100vw - 320px) / (1920 - 320)));
            border-radius: 15px;
            box-shadow: 20px 15px 40px 0 rgba(87, 64, 2, 0.08);
            background-color: $white;
        }

}
@media (max-width: 414px) {
    .mpsitio{
        &-box{
            height: calc(250px + (300 - 250) * ((100vw - 320px) / (1920 - 320)));
        }
        .mpsitio-box-icon-flecha {
            display: inline;
            background-color: #fd8324;
            padding: 5px 10px;
            border-radius: 25%;
            margin-left: calc(190px + (280 - 190) * ((100vw - 300px) / (1920 - 300)));
        }
    }
}

@media (min-width: 1220px) and (max-width: 1220px) {
    .mpsitio {
        &-box {
            height: calc(250px + (300 - 250) * ((100vw - 320px) / (1920 - 320)));
        }

        a {
            &:hover {

                .mpsitio-box-icon-flecha {
                    margin-left: calc(140px + (170 - 140) * ((100vw - 300px) / (1920 - 300)));
                }
            }
        }
    }
}

//media query laptop
@media (min-width: 1440px) and (max-width: 1440px) {
    .mpsitio{
        &-box{
            height: calc(250px + (300 - 250) * ((100vw - 320px) / (1920 - 320)));
        }
        a{
            &:hover{

                .mpsitio-box-icon-flecha {
                    margin-left: calc(100px + (240 - 100) * ((100vw - 300px) / (1920 - 300)));
                }
            }
        }
    }
}

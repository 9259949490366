.btns {
    width: fit-content;

    &-seeall, &-mas {
        font-weight: 900;
        font-size: calc(12px + (14 - 12) * ((100vw - 375px) / (1920 - 375)));
        line-height: 54px;
        letter-spacing: calc(1.56px + (1.82 - 1.56) * ((100vw - 375px) / (1920 - 375)));
        color: $black;
        display: flex;
        z-index: 1;
        align-items: center;
        position: relative;
        text-transform: uppercase;
        transition: all .2s ease;

        & span {
            display: inline-block;
            margin-left: 26px;
            transition: all .3s ease;

            & i {
                font-size: calc(12px + (14 - 12) * ((100vw - 375px) / (1920 - 375)));
            }
        }

        &:before {
            content: '';
            position: absolute;
            right: -9px;
            z-index: -1;
            background-color: $purple;
            color: $white;
            width: calc(27px + (32 - 27) * ((100vw - 375px) / (1920 - 375)));
            height: calc(27px + (32 - 27) * ((100vw - 375px) / (1920 - 375)));
            border-radius: calc(7px + (10 - 7) * ((100vw - 375px) / (1920 - 375)));
            transition: all .3s ease;
        }

        &:hover {
            text-decoration: none;
            color: $white;
            padding-left: 25px;

            & span {
                margin-left: 10px;
                padding-right: 10px;
            }

            &:before {
                width: 100%;
            }
        }
    }

    &-mas {
        color: $white;

        & span {

            & i {
                color: $pumpkin-orange;
            }
        }

        &:before {
            background-color: $white;
        }

        &:hover {
            color: $pumpkin-orange;
        }
    }

    &-visit {
        color: $white;
        background-color: $purple;
        width: 161px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: calc(7px + (10 - 7) * ((100vw - 375px) / (1920 - 375)));
        font-weight: 900;
        font-size: calc(12px + (14 - 12) * ((100vw - 375px) / (1920 - 375)));
        line-height: 54px;
        letter-spacing: calc(1.56px + (1.82 - 1.56) * ((100vw - 375px) / (1920 - 375)));
        text-transform: uppercase;

        &:hover {
            color: $white;
            text-decoration: none;
        }

        & i {
            margin-left: 10px;
            font-size: calc(12px + (14 - 12) * ((100vw - 375px) / (1920 - 375)));
        }
    }

    &-back {
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        font-size: calc(10px + (12 - 10) * ((100vw - 375px) / (1920 - 375)));
        background-color: rgba(255, 255, 255, 0.3);
    }
    &_nobis{
        @include respond('phone-small') {
            display: block;
            width: 100%;
                  }
        @include respond('phone-iphone') {
            display: block;
            width: 100%;
        }
    }
}

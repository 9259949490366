.top {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: fixed;
    z-index: 12;
    transition: all .2s ease;
    //background-color: $purple;

    &-logo {
        width: calc(50px + (75 - 50) * ((100vw - 320px) / (1920 - 320)));
    }

    &-scroll {
        background-color: $pumpkin-orange;
    }

    & .buscador {
        margin-top: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));

        & input {
            font-size: 16px;
            background-color: transparent;
            border: none;
            border-bottom: 2px solid rgba(0,0,0,0.4);
            padding-bottom: 8px;
            margin-bottom: calc(30px + (60 - 30) * ((100vw - 320px) / (1920 - 320)));
            width: calc(250px + (350 - 250) * ((100vw - 320px) / (1920 - 320)));

            &::placeholder {
                color: rgba(0,0,0,0.4);
                text-transform: uppercase;
                letter-spacing: 2.08px;
            }

            &:focus {
                outline: none;
            }
        }
    }

    & .menu {
        position: fixed;
        z-index: 10;
        top: 0;
        right: 0;
        width: calc(320px + (680 - 320) * ((100vw - 320px) / (1920 - 320)));
        height: 100vh;
        background-color: $white-fa;
        box-shadow: 0 20px 40px 0 rgba(87, 64, 2, 0.15);
        text-align: left;
        visibility: hidden;
        opacity: 0;
        transition: all .2s ease-in-out;

        @include respond(tab) {
            width: 100%;
            height: 100%;
        }

        &-show {
            cursor: pointer;
            margin-top: calc(0px + (12 - 0) * ((100vw - 320px) / (1920 - 320)));
            width: calc(32px + (47 - 32) * ((100vw - 320px) / (1920 - 320)));
        }

        &-active {
            visibility: visible;
            opacity: 1;
            transition: all .2s ease-in-out;
        }

        & ul {
            padding: 0;
            margin: 0;

            & li {
                list-style: none;
                margin-bottom: calc(10px + (30 - 10) * ((100vw - 320px) / (1920 - 320)));

                &:last-child {
                    margin-bottom: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));
                }
            }
        }

        & a {
            font-family: Poppins;
            color: $black;
            font-size: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
            font-weight: bold;

            &:hover {
                color: $pumpkin-orange;
            }
        }

        &-social {
            background-color: $pumpkin-orange;
            min-height: 180px;
            position: absolute;
            bottom: 0;
            width: 100%;

            @include respond(tab) {
                position: absolute;
                bottom: 0;
                width: 100%;
            }

            &_title {
                color: $white;
                font-size: 30px;
                font-family: Poppins;
                padding-top: 35px;
            }

            &_icons {
                margin-top: 20px;

                & ul {
                    margin: 0;
                    padding: 0;

                    & li {
                        display: inline-block;

                        &:not(:last-child) {
                            margin-right: calc(25px + (50 - 25) * ((100vw  - 320px) / (1920 - 320)));
                        }

                        & i {
                            font-size: calc(18px + (25 - 18) * ((100vw  - 320px) / (1920 - 320)));
                        }
                    }
                }
            }
        }

        &-buscador {
            display: inline-block;
            position: relative;
            margin-right: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));

            &_lupa {
                padding: 0 !important;
                margin: 0 !important;
                border: none !important;
                width: 38px !important;
                height: 38px;
                position: absolute;
                background-image: url("/images/icono-lupa-gris.svg");
                background-repeat: no-repeat;
                background-position: center;

                &:hover {
                    background-image: url("/images/icono-lupa-hover-naranja.svg");
                }
            }
        }

        &-close {
            display: inline-block;
            cursor: pointer;
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }
}

.somos,
.carousel,
.propuestas,
.luchamos,
.aguila,
.directorio,
.candidatos-page,
.yuawi-interior,
.prensa-articulos,
.campana-testimoniales,
.politica101-material {

    & .swiper-container {
        padding-left: calc(0px + (90 - 0) * ((100vw - 480px) / (1920 - 480)));
        z-index: 2;
    }

    & .swiper-button-next:after,
    & .swiper-container-rtl .swiper-button-prev:after,
    & .swiper-button-prev:after,
    & .swiper-container-rtl .swiper-button-next:after {
        width: 30px;
        height: 30px;
        border-radius: 5px;
        font-size: 18px;
        font-weight: 900;
        color: $white;
        font-family: 'Font Awesome 5 Free';
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    & .swiper-button-next:after,
    & .swiper-container-rtl .swiper-button-prev:after {
        content: '\f054';
    }

    & .swiper-button-prev:after,
    & .swiper-container-rtl .swiper-button-next:after {
        content: '\f053';
    }

    & .swiper-button-prev.swiper-button-disabled,
    & .swiper-button-next.swiper-button-disabled {
        transition: all .2s ease;
        opacity: 1;

        &:after {
            background-color: transparent;
        }
    }
}

.carousel {

    & .swiper-container {
        margin-left: calc(15px + (100 - 15) * ((100vw - 480px) / (1920 - 480)));

        @include respond(tab) {
            margin-left: 21px;
        }
    }

    & .swiper-button-prev, & .swiper-button-next {
        top: 47%;

        @include respond(phone) {
            display: none;
        }
    }

    & .swiper-button-prev.swiper-button-disabled,
    & .swiper-button-next.swiper-button-disabled {

        &:after {
            color: $white;
            background-color: transparent;
        }
    }
}

.somos,
.propuestas,
.luchamos,
.directorio,
.candidatos-page,
.campana-testimoniales {

    & .swiper-container {
        margin-left: calc(0px + (80 - 0) * ((100vw - 480px) / (1920 - 480)));

        @include respond(tab) {
            margin-left: 15px;
        }
    }

    & .swiper-slide {

        &:nth-child(odd) {
            margin-top: calc(0px + (-51 - 0) * ((100vw - 480px) / (1920 - 480)));

            @include respond(tab) {
                margin-top: 0;
            }
        }

        &:nth-child(even) {
            margin-top: calc(0px + (51 - 0) * ((100vw - 480px) / (1920 - 480)));

            @include respond(tab) {
                margin-top: 0;
            }
        }
    }

    & .swiper-button-prev,
    & .swiper-button-next {

        @include respond(tab) {
            display: none;
        }
    }

    & .swiper-button-prev.swiper-button-disabled,
    & .swiper-button-next.swiper-button-disabled {

        &:after {
            color: $black;
        }
    }
}

.propuestas {

    & .swiper-container {
        padding-top: 50px;
        padding-bottom: 50px;

        @include respond(phone) {
            padding-top: 0;
        }
    }

    & .swiper-slide {

        &:nth-child(odd) {
            margin-top: 0;
        }

        &:nth-child(even) {
            margin-top: 0;
        }
    }
}

.aguila {

    & .swiper-container {
        padding-left: 0;
        padding-bottom: calc(30px + (50 - 30) * ((100vw - 375px) / (1920 - 375)));

        @include respond(tab) {
            padding-left: 15px;
        }
    }
}

.luchamos,
.yuawi-interior,
.prensa-articulos,
.campana-testimoniales {

    & .swiper-container {
        padding-top: calc(30px + (90 - 30) * ((100vw - 375px) / (1920 - 375)));
        padding-bottom: calc(30px + (90 - 30) * ((100vw - 375px) / (1920 - 375)));
    }
}

.directorio,
.somos {

    & .swiper-container {
        padding-bottom: calc(30px + (90 - 30) * ((100vw - 375px) / (1920 - 375)));
    }
}

.candidatos-page {

    & .swiper-container {

        & a {
            color: $black;

            :hover {
                text-decoration: none;
            }
        }
    }

    & .swiper-slide {

        &:nth-child(odd) {
            margin-top: 0;
        }

        &:nth-child(even) {
            margin-top: 0;
        }
    }

}

.yuawi-interior,
.prensa-articulos,
.politica101-material {

    & .swiper-button-prev,
    & .swiper-button-next {

        @include respond(tab) {
            display: none;
        }
    }
}


.campana-testimoniales {

    & .swiper-container {
        margin-left: 0;
    }

    & .swiper-slide {

        &:nth-child(1) .campana-testimoniales_box {
            background-color: #45cff0;
        }

        &:nth-child(2) .campana-testimoniales_box {
            background-color: #ffe1a4;
        }

        &:nth-child(3) .campana-testimoniales_box {
            background-color: #b5b9fd;
        }

        &:nth-child(4) .campana-testimoniales_box {
            background-color: #fea369;
        }

        &:nth-child(5) .campana-testimoniales_box {
            background-color: #4adeb0;
        }

        &:nth-child(6) .campana-testimoniales_box {
            background-color: #979057;
        }
    }

}

.politica101-material {

    & .swiper-slide {
        width: auto;
    }

}

.campana-list {
    & .swiper-pagination-bullet {
        background-color: $white;
    }

    & .swiper-pagination-bullet-active {
        background-color: $white;
    }
}


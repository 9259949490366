.cosmeticos{
		background: -prefix-linear-gradient(top, #c3c3c3, #fafafa);
		position:relative; 
		z-index:1;
	
	&-white-four{
		background: $white-four; 
	}

	.contenedor{
    	position: absolute;
    	display: flex;
    	align-items: center;
    	height: 100%;
		}

	.circulo-one{
	 position: relative;
	 bottom: calc(20px + (45 - 20) * ((100vw - 300px) / (1920 - 300)));
	 left: calc(-5px + (-30 - 5) * ((100vw - 375px) / (1920 - 375)));
     width: calc(40px + (100 - 40) * ((100vw - 375px) / (1920 - 375)));
     height: calc(40px + (100 - 40) * ((100vw - 375px) / (1920 - 375)));
     -moz-border-radius: 50%;
     -webkit-border-radius: 50%;
     border-radius: 50%;
     background: $yellow-orage;
     z-index: -1;
	}

	.circulo-two{
	 bottom: calc(80px + (70 - 80) * ((100vw - 300px) / (1920 - 300)));
	 position: relative;
	 left: calc(-5px + (-30 - 5) * ((100vw - 375px) / (1920 - 375)));
     width: calc(40px + (100 - 40) * ((100vw - 375px) / (1920 - 375)));
     height: calc(40px + (100 - 40) * ((100vw - 375px) / (1920 - 375)));
     -moz-border-radius: 50%;
     -webkit-border-radius: 50%;
     border-radius: 50%;
     background: $yellow-orage;
     z-index: -1;
	}

	.position{
		&-one{
			top:calc(700px + (440 - 700) * ((100vw - 300px) / (1920 - 300)));
			left: calc(180px + (1500 - 180) * ((100vw - 375px) / (1920 - 375)));
			position: relative;
			z-index: 2;
		}
		&-two{
			top:calc(150px + (200 - 150) * ((100vw - 300px) / (1920 - 300)));
			left: calc(-10px + (70 + 10) * ((100vw - 375px) / (1920 - 375)));
			position: relative;
			z-index: 2;
		}	
	}
	


	.back{

		&-radiu{
			&-medi
			{
				border-radius: 90px 90px 0 0;
			}
			&-left
			{
				border-radius: 90px 0 0 90px;
			}
			&-40
			{
				border-radius: 40px;
			}

			&-54
			{
				border-radius: 54px;
			}

			&-60
			{
				border-radius: 60px;
			}

		}
		&-pump-orage
		{
			background: $pumpkin-orange;	
		}
		&-grey-cos
		{
			background: $grey-cos;
		}
		&-pink-ff
		{
			background: $pink-ff;
		}
	}

	.txt{

		&-bold
		{
			font-weight: bold;
		}

		&-20
		{
			font-size: calc(15px + (20 - 15) * ((100vw - 375px) / (1920 - 375)));
		}

		&-30
		{
			font-size: calc(20px + (30 - 20) * ((100vw - 375px) / (1920 - 375)));
		}

		&-45
		{
			font-size: calc(30px + (45 - 30) * ((100vw - 375px) / (1920 - 375)));
		}

		&-60
		{
			font-size: calc(50px + (60 - 50) * ((100vw - 375px) / (1920 - 375)));
		}
		&-85
		{
			font-size: calc(60px + (85 - 60) * ((100vw - 375px) / (1920 - 375))) !important;
		}

		&-color
		{
			&-white
			{
				color: $white;
			}
			&-white-three
			{
				color: $white-three;
			}
			&-brownish-grey
			{
				color: $brownish-grey;
			}
			&-pink-ff
			{
				color: $pink-ff;
			}
			&-bright-orange
			{
				color: $bright-orange;
			}

		}

	}
}


//@if $breakpoint == phone-small {
   //     @media only screen and (max-width: 20em) { @content }; // 320px
    //}
    //@if $breakpoint == phone-iphone {
      //  @media only screen and (max-width: 23.4375em) { @content }; // 375px
    //}
    //@if $breakpoint == phone {
      //  @media only screen and (max-width: 30em) { @content }; // 480px
    //}
    //@if $breakpoint == tab {
      //  @media only screen and (max-width: 48em) { @content }; // 768px
    //}
    //@if $breakpoint == tab-port {
      //  @media only screen and (max-width: 62em) { @content }; //992px
    //}
    //@if $breakpoint == old-desk {
      //  @media only screen and (max-width: 64em) { @content }; //1024px
    //}
    //@if $breakpoint == tab-land {
      //  @media only screen and (max-width: 75em) { @content }; //1200px
    //}
   /* @if $breakpoint == desktop {
       @media only screen and (max-width: 90em) { @content }; //1440px
    }*/

.all-mc {
    padding: calc(20px + (55 - 20) * ((100vw - 300px) / (1920 - 300))) 0;
    background-color: $white;

    & ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;

        & li {

        }
    }
}
.sisze{width: 160px;}

.politica101 {
    padding-top: calc(80px + (160 - 80) * ((100vw - 375px) / (1920 - 375)));
    //padding-bottom: calc(120px + (260 - 120) * ((100vw - 375px) / (1920 - 375)));
    padding-bottom: calc(60px + (130 - 60) * ((100vw - 375px) / (1920 - 375)));

    & a {
        color: $black;

        &:hover {
            text-decoration: none;
        }
    }

    & ul {
        margin: 0;
        padding: 0;
        list-style: none;

        & li {
            display: inline-block;

            &:not(:last-child) {
                margin-right: calc(0px + (35 - 0) * ((100vw - 375px) / (1920 - 375)));
            }

            @include respond(tab-land) {
                margin-bottom: 25px;
            }
        }
    }

    &-list {
        margin: 60px 0;

        @include respond(tab) {
            text-align: center;
        }
    }

    &-box {
        position: relative;
        /*width: calc(270px + (398 - 270) * ((100vw - 375px) / (1920 - 375)));*/
        width: 100%;
        height: calc(242px + (311 - 242) * ((100vw - 375px) / (1920 - 375)));
        padding: 0 0 5px;
        border-radius: 15px;
        box-shadow:
            calc(10px + (20 - 10) * ((100vw - 375px) / (1920 - 375)))
            calc(10px + (20 - 10) * ((100vw - 375px) / (1920 - 375)))
            calc(10px + (40 - 10) * ((100vw - 375px) / (1920 - 375))) 0 rgba(87, 64, 2, 0.25);
        background-color: $white;
        transition: all .2s ease;
        margin-bottom: 30px;

        @include respond(tab-land) {
            margin-bottom: 25px;
        }

        &:hover {
            transform: translateY(-10px);

            & .politica101-box_image img {
                transform: scale(1.02);
            }

            & .politica101-box_arrow {
                opacity: 1;
            }
        }

        &_tag {
            position: absolute;
            z-index: 1;
            top: 8px;
            left: 8px;
            width: 164px;
            height: 31px;
            padding: 8px 22px 7px;
            border-radius: 15px;
            background-color: rgba(250, 250, 250, 0.8);
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.24px;
            text-transform: uppercase;
            text-align: center;

            & strong {
                letter-spacing: normal;
            }

        }

        &_arrow {
            position: absolute;
            z-index: 1;
            top: 8px;
            right: 8px;
            background-color: $pumpkin-orange;
            color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            width: 30px;
            height: 30px;
            border-radius: 5px;
            opacity: 0;
            transition: all .2s ease;
        }

        &_image {
            -webkit-border-top-left-radius: 15px;
            -webkit-border-top-right-radius: 15px;
            -moz-border-radius-topleft: 15px;
            -moz-border-radius-topright: 15px;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
            overflow: hidden;
            /*width: calc(270px + (398 - 270) * ((100vw - 375px) / (1920 - 375)));*/
            width: 100%;
            height: calc(171px + (217 - 171) * ((100vw - 375px) / (1920 - 375)));

            & img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all .2s ease;
            }
        }

        &_text {
            display: flex;
            align-items: center;
            height: calc(66px + (89 - 66) * ((100vw - 375px) / (1920 - 375)));
            /*margin: 5px 14px 0;*/
            margin: 0 14px;
            text-align: left;
            font-family: Poppins;
            font-size: calc(16px + (20 - 16) * ((100vw - 375px) / (1920 - 375)));
            font-weight: bold;
            line-height: calc(22px + (26 - 22) * ((100vw - 375px) / (1920 - 375)));
        }
    }
}

.banner {
    &-violencia {
        padding-bottom: calc(60px + (130 - 60) * ((100vw - 375px) / (1920 - 375)));
    }
}

.opinion {
    margin-top: calc(30px + (100 - 30) * ((100vw - 375px) / (1920 - 375)));
    padding: calc(60px + (88 - 60) * ((100vw - 375px) / (1920 - 375))) 0;

    &-title {
        font-family: Poppins;
        font-weight: bold;
        font-size: calc(25px + (30 - 25) * ((100vw - 375px) / (1920 - 375)));
        line-height: calc(30px + (38 - 30) * ((100vw - 375px) / (1920 - 375)));
    }

    &-box {
        display: flex;

        &_img {
            width: calc(78px + (120 - 78) * ((100vw - 375px) / (1920 - 375)));
            height: calc(78px + (120 - 78) * ((100vw - 375px) / (1920 - 375)));
            margin-right: calc(10px + (18 - 10) * ((100vw - 375px) / (1920 - 375)));

            & img {
                border-radius: 15px;
                width: calc(78px + (120 - 78) * ((100vw - 375px) / (1920 - 375)));
                height: calc(78px + (120 - 78) * ((100vw - 375px) / (1920 - 375)));
                object-fit: cover;
            }
        }

        &_form {
            width: 100%;

            & textarea {
                border: 1px solid rgba(59, 59, 59, 0.1);
                border-radius: 15px;
                width: 100%;
                padding: calc(10px + (15 - 10) * ((100vw - 375px) / (1920 - 375)));
                height: calc(78px + (120 - 78) * ((100vw - 375px) / (1920 - 375)));
                resize: none;
            }
        }
    }

    &-comments {
        font-family: "Roboto", sans-serif;
        font-weight: normal;
        padding-bottom: 22px;
        border-bottom: 1px solid rgba(59, 59, 59, 0.1);
        font-size: calc(12px + (14 - 12) * ((100vw - 375px) / (1920 - 375)));
        line-height: calc(16px + (19 - 16) * ((100vw - 375px) / (1920 - 375)));
        letter-spacing: calc(1.56px + (1.82 - 1.56) * ((100vw - 375px) / (1920 - 375)));
        margin-bottom: calc(20px + (50 - 20) * ((100vw - 375px) / (1920 - 375)));
        display: flex;
        justify-content: space-between;
        text-transform: uppercase;
        margin-top: 50px;

        & span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: fit-content;
            height: 35px;
        }

        &-tag {
            display: flex;
            align-items: center;
            justify-content: center;
            width: fit-content;
            height: 35px;
            border-radius: 15px;
            background-color: rgba(111, 31, 180, 0.08);
            padding: 0 calc(10px + (15 - 10) * ((100vw - 375px) / (1920 - 375)));
        }
    }
}

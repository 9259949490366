.propuestas {
    position: relative;
    background-color: $white-fa;
    padding-top: calc(120px + (260 - 120) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: calc(120px + (280 - 120) * ((100vw - 320px) / (1920 - 320)));
    text-align: center;

    &-puntos {
        position: absolute;
        top: -25.8%;
        right: 0;
        z-index: 1;

        @include respond(tab) {
            display: none;
        }
    }

    &-list {
        margin-top: 60px;
    }

    &-box {
        width: calc(280px + (398 - 280) * ((100vw - 320px) / (1920 - 320)));
        height: calc(450px + (495 - 450) * ((100vw - 320px) / (1920 - 320)));
        padding: 20px 22px 80px 10px;
        border-radius: 15px;
        box-shadow: 20px 15px 40px 0 rgba(87, 64, 2, 0.08);
        background-color: $white;

        &_number {
            opacity: 0.05;
            font-family: Poppins;
            font-size: calc(30px + (45 - 30) * ((100vw - 320px) / (1920 - 320)));
            font-weight: bold;
            text-align: left;
            color: $black;
        }

        &_title {
            padding: 0 22px;
            font-family: Poppins;
            font-size: calc(18px + (25 - 18) * ((100vw - 320px) / (1920 - 320)));
            font-weight: bold;
            line-height: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
        }

        &_description {
            margin-top: 20px;
            padding: 0 22px;
            font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
            line-height: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
        }
    }
}

.mapa {
    padding-top: calc(80px + (135 - 80) * ((100vw - 375px) / (1920 - 375)));
    padding-bottom: calc(152px + (276 - 152) * ((100vw - 375px) / (1920 - 375)));

    &-titles {
        font-family: Poppins;
        font-weight: bold;
        display: flex;
        margin-bottom: 25px;

        & img {
            margin-right: 10px;
        }
    }

    &-listado {

        & ul {
            list-style: none;
            margin: 0;
            padding: 0;

            & li {
                margin-bottom: 25px;

                & ul {
                    margin-left: 30px;
                    margin-top: 20px;

                    & ul {
                        margin-left: 0;
                    }
                }
            }
        }

        & a {
            color: $black;
            display: flex;
            align-items: baseline;

            & img {
                margin-right: 12px;
            }
        }
    }
}

.politica101 {

    &-interno {
        padding-top: calc(230px + (283 - 230) * ((100vw - 375px) / (1920 - 375)));

        &_conoce {
            padding-top: calc(60px + (180 - 60) * ((100vw - 375px) / (1920 - 375)));
            padding-bottom: calc(60px + (150 - 60) * ((100vw - 375px) / (1920 - 375)));
        }

        & ul {
            list-style: none;
            margin-bottom: 0;

            & li {
                position: relative;

                &:not(:last-child) {
                    margin-bottom: 1.6rem;
                }


                &:before {
                    content: '\2022';
                    color: $pumpkin-orange;
                    font-weight: bold;
                    font-size: 2rem;
                    position: absolute;
                    left: -30px;
                }
            }
        }
    }
}

a{
    font-weight: bold;
    color: #000000;
    &:link, :visited, :active{
        text-decoration:none;
    }
    &:hover{
        color: #000000;
    }
}

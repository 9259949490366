/*css for politica-101*/
$font:Poppins;
$white:#ffffff;

@mixin border-radius($rad20) {
  -webkit-border-radius: $rad20;
  border-radius: $rad20;
}

/*.app{overflow-x: hidden;}*/
.header-interno_image {
  background: linear-gradient(127deg, #ff4e00 29%, #fd8324 57%);
  display: flex;
  align-items: center;
  position: relative;
}
.header-interno_image-subtitle {
  font-family: $font;
    font-size: calc(16px + (60 - 16) * ((100vw - 375px) / (1920 - 375)));
    line-height: 1.5;
    color: $white;
    margin-top: calc(0px + (60 - 0) * ((100vw - 375px) / (1920 - 375)));

}
.header-interno_image-title {
    font-family: $font;
    font-weight: bold;
    font-size: calc(25px + (60 - 25) * ((100vw - 375px) / (1920 - 375)));
    line-height: calc(25px + (90 - 25) * ((100vw - 375px) / (1920 - 375)));
    color: $white;
    &-85{
    font-size: calc(30px + (60 - 30) * ((100vw - 375px) / (1920 - 375)));
    font-family: $font;
    font-weight: bold;
    line-height: calc(35px + (90 - 35) * ((100vw - 375px) / (1920 - 375)));
    color: $white;
    }
}
.block-gob{
  @include border-radius(20px);
  -webkit-box-shadow: 1px 1px 20px 1px rgba(0,0,0,0.2);
  box-shadow: 1px 1px 20px 1px rgba(0,0,0,0.2);
  padding: 20px 30px;
  margin-top: -20%;
  @include respond(old-desk) {
      margin-top: -20%;
  }
  @include respond(phone) {
    margin-top: -40%;
    padding: 15px 15px;
  }
}
.cabsolute{
  position:absolute;
  z-index:10;
  @include respond(old-desk) {
      position:relative;
  }
}
.tabsolute{position:absolute; z-index:10;}
.crelative{position:relative;}
.border20{
  @include border-radius(20px);
}
.bshadow{
  -webkit-box-shadow: 1px 1px 20px 1px rgba(0,0,0,0.2);
  box-shadow: 1px 1px 20px 1px rgba(0,0,0,0.2);
}
.tgob{top:-80px;}
.ttop{
  top:22%;
  @include respond(desktop) {
      top: 8%;
  }
}
.centerelement{
  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
}
.lleft{
  left:30%;
  @include respond(desktop) {
    left:22%;
  }
  @include respond(tab-land) {
    left:20%;
  }  
  @include respond(old-desk) {
    left:18%;
  }
  @include respond(phone) {
    left:0%;
  }
}
.btom{
  top: -22%;
}
.numeral{
  width: max-content;
  padding: 7px 23px;
  margin: 0 auto;
  position: absolute;
  z-index: 20;
  top: -10%;
  left: 33%;
  border: 5px solid #fff;
  @include border-radius(35px);
  @include respond(desktop) {
    left: 27%;
  }
  @include respond(old-desk) {
    left: 25%;
    top:-9%;
  }
  @include respond(tab) {
    left: 42%;
  }
  @include respond(phone) {
      left: 37%;
  }

}
.numeral2{
  width: max-content;
  padding: 7px 23px;
  margin: 0 auto;
  position: absolute;
  z-index: 20;
  top: -10%;
  left: 33%;
  border: 5px solid #fff;
  @include border-radius(35px);
  @include respond(desktop) {
    left: 27%;
  }
  @include respond(tab) {
    left: 42%;
  }
  @include respond(phone) {
      left: 37%;
  }

}
.tt-regular{
  font-family: $font;
  font-weight: 400;
  font-size: calc(25px + (45 - 25) * ((100vw - 375px) / (1920 - 375)));
  line-height: 1.22;
}
.tt-bold{
  font-family: $font;
  font-weight: 700;
  font-size: calc(25px + (45 - 25) * ((100vw - 375px) / (1920 - 375)));
  line-height: 1.22;
}
.txt-bold{
  font-family: $font;
  font-weight: 700;
  font-size: calc(25px + (30 - 25) * ((100vw - 375px) / (1920 - 375)));
  line-height: 1.5;
}
.txt-30{
  font-size: calc(25px + (30 - 25) * ((100vw - 375px) / (1920 - 375)));
  font-family: $font;
  line-height: 1.5;
}
.bogris{
  -webkit-border-radius: 20px 20px 0 0;
  border-radius: 20px 20px 0 0;

    & img {
      margin-top: -70px;
      @include respond(desktop) {
        margin-top: -45px;
      }
      @include respond(old-desk) {
          margin-top: -40px;
      }
      @include respond(phone) {
        margin-top: 0px;
      }
    }
}


.grad{
  background: -moz-linear-gradient(to bottom,  rgba(255,255,255,0), rgba(255,255,255,1));
  background: -webkit-linear-gradient(to bottom,  rgba(255,255,255,0), rgba(255,255,255,1));
  background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1));
  margin-top: -140px;
  z-index: 10;
  position: relative;
  padding-top: 140px;
  margin-bottom:100px;
}

.vmas{
  font-weight: 700;
  font-size: calc(12px + (14 - 12) * ((100vw - 375px) / (1920 - 375)));
  text-transform:uppercase;
  padding: 6px 30px 5px 30px;
  & i {
    margin-left: 10px;
  }
}

.fsixed{width:59%;}

.textra{
  margin-top:0px;
  @include respond(phone) {
    margin-top: 50%;
  }
}

.yuawi {
    background-color: $white;
    /*height: calc(702px + (794 - 702) * ((100vw - 375px) / (1920 - 375)));*/
    height: auto;

    &-box {
        height: calc(395px + (794 - 395) * ((100vw - 375px) / (1920 - 375)));
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.aguila {
    position: relative;
    padding: calc(0px + (160 - 0) * ((100vw - 375px) / (1920 - 375))) 0;

    & a {
        color: $black;

        &:hover {
            text-decoration: none;
        }
    }

    &-box {
        height: calc(365px + (865 - 365) * ((100vw - 375px) / (1920 - 375)));
        display: flex;
        flex-direction: column;
        justify-content: center;

        &_image {
            height: calc(395px + (865 - 395) * ((100vw - 375px) / (1920 - 375)));
            overflow: hidden;
        }
    }

    &-list {
        margin-top: calc(-157px + (-190 - -157) * ((100vw - 375px) / (1920 - 375)));

        & ul {
            list-style: none;
            margin: 0;
            padding: 0;

            & li {
                display: inline-block;

                &:not(:last-child) {
                    padding-right: 16px;
                }

                & a {
                    color: $black;
                }
            }
        }

        &_box {
            width: calc(252px + (398 - 252) * ((100vw - 375px) / (1920 - 375)));
            height: calc(196px + (311 - 196) * ((100vw - 375px) / (1920 - 375)));
            padding: 0 0 25px;
            border-radius: 15px;
            overflow: hidden;
            box-shadow:
                calc(10px + (20 - 10) * ((100vw - 375px) / (1920 - 375)))
                calc(10px + (15 - 10) * ((100vw - 375px) / (1920 - 375)))
                calc(10px + (40 - 10) * ((100vw - 375px) / (1920 - 375))) 0 rgba(87, 64, 2, 0.25);
            background-color: $white;

            &-texts {
                font-size: calc(14px + (20 - 14) * ((100vw - 375px) / (1920 - 375)));
                line-height: calc(22px + (26 - 22) * ((100vw - 375px) / (1920 - 375)));
                margin: 0 10px;
                display: flex;
                align-items: center;
                height: calc(48px + (70 - 48) * ((100vw - 375px) / (1920 - 375)));

                &_title {
                    font-family: Poppins;
                    font-weight: bold;
                    display: inline-block;
                }

                &_episode {
                    font-size: calc(12px + (14 - 12) * ((100vw - 375px) / (1920 - 375)));
                    line-height: calc(16px + (19 - 16) * ((100vw - 375px) / (1920 - 375)));
                    display: inline-block;
                    margin-left: calc(7px + (15 - 7) * ((100vw - 375px) / (1920 - 375)));
                    text-transform: uppercase;

                    &:before {
                        content: '';
                        height: calc(10px + (12 - 10) * ((100vw - 375px) / (1920 - 375)));
                        border-left: 1px solid $black;
                        padding-right: calc(10px + (20 - 10) * ((100vw - 375px) / (1920 - 375)));;
                    }
                }
            }

            &-image {
                height: calc(153px + (241 - 153) * ((100vw - 375px) / (1920 - 375)));

                & img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}

.newsletter {
    background: rgb(255,186,75);
    background: linear-gradient(142deg, rgba(255,186,75,1) 18%, rgba(255,131,0,1) 82%);
    text-align: center;
    padding: 85px 0 70px;
    position: relative;

    &-puntos {
        position: absolute;
        top: calc(-22px + (-19 - -22) * ((100vw - 375px) / (1920 - 375)));
        left: calc(38px + (176 - 38) * ((100vw - 375px) / (1920 - 375)));

        @include respond(tab) {
            width: 90px;
        }

        @include respond(phone) {
            width: 60px;
        }
    }

    &-box {

        &_title {
            padding: 20px 0;
            font-family: Poppins;
            font-weight: bold;
            font-size: calc(50px + (119 - 50) * ((100vw - 375px) / (1920 - 375)));
            line-height: calc(80px + (145 - 80) * ((100vw - 375px) / (1920 - 375)));
            letter-spacing: calc(0.8px + (47.6 - 0.8) * ((100vw - 375px) / (1920 - 375)));
            color: transparent;
            -webkit-text-stroke: calc(1.8px + (4 - 1.8) * ((100vw - 375px) / (1920 - 375))) #ffffff;
            text-transform: uppercase;
        }

        & p {
            font-size: calc(16px + (20 - 16) * ((100vw - 375px) / (1920 - 375)));
            line-height: calc(24px + (30 - 24) * ((100vw - 375px) / (1920 - 375)));
            margin-bottom: calc(50px + (30 - 50) * ((100vw - 375px) / (1920 - 375)));
        }

        &_input {
            border: none;
            padding: 16px 0 15px 25px;
            border-radius: 30px;
            box-shadow: 0 5px 25px 0 rgba(29, 75, 139, 0.16);
            background-color: $white;
            color: rgba(0, 0, 0, 0.5);
            font-size: calc(12px + (14 - 12) * ((100vw - 375px) / (1920 - 375)));
            line-height: calc(16px + (19 - 16) * ((100vw - 375px) / (1920 - 375)));
            letter-spacing: calc(1.56px + (1.82 - 1.56) * ((100vw - 375px) / (1920 - 375)));
            margin-bottom: 20px;
            text-transform: uppercase;
            /*width: calc(270px + (650 - 270) * ((100vw - 375px) / (1920 - 375)));*/
            width: 100%;
            display: block;

            &:focus {
                outline: none;
            }

            &::placeholder {
                color: rgba(0, 0, 0, 0.5);
            }
        }
    }

    & .btns-submit {
        z-index: 1;
        position: absolute;
        bottom: 3px;
        right: 25px;
        cursor: pointer;

        & button {
            border: none;
            background-color: transparent;

            &:before {
                right: -4px;
            }
        }
    }
}

.noticias {
    padding: calc(120px + (260 - 120) * ((100vw - 375px) / (1920 - 375))) 0;
    background-image: url("/images/dots/puntos-morados-6.svg");
    background-repeat: no-repeat;
    background-position: 99% 247px;

    @include respond(tab) {
        background-image: none;
    }

    &-box {
        margin: calc(30px + (60 - 30) * ((100vw - 375px) / (1920 - 375))) 0;
    }

    & .news-box {
        display: inline-flex;

        &:not(:last-child) {
            margin: 0 20px 50px 0;
        }
    }
}

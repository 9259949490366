// MEDIA QUERY MANAGER
/*

0 - 600px:          Phone
600 - 900px:        Tablet Portrait
900 - 1200px:       Tablet Landscape
[1200 - 1800px] is where our normal styles apply
1800px + :          Big Desktop

$breakpoint argument choices
- phone
- tab-port
- tab-land
- big-desktop

1em = 16px;

*/
@mixin respond($breakpoint) {
    @if $breakpoint == phone-small {
        @media only screen and (max-width: 20em) { @content }; // 320px
    }
    @if $breakpoint == phone-iphone {
        @media only screen and (max-width: 23.4375em) { @content }; // 375px
    }
    @if $breakpoint == phone {
        @media only screen and (max-width: 30em) { @content }; // 480px
    }
    @if $breakpoint == tab {
        @media only screen and (max-width: 48em) { @content }; // 768px
    }
    @if $breakpoint == tab-port {
        @media only screen and (max-width: 62em) { @content }; //992px
    }
    @if $breakpoint == old-desk {
        @media only screen and (max-width: 64em) { @content }; //1024px
    }
    @if $breakpoint == tab-land {
        @media only screen and (max-width: 75em) { @content }; //1200px
    }
    @if $breakpoint == desktop {
        @media only screen and (max-width: 90em) { @content }; //1440px
    }
}


/*
    Halfs Columns
 */

@mixin col-half($number){
    max-width:(8.33333333% * $number) + 4.16666665%;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.prensa {
    padding-bottom: calc(152px + (275 - 152) * ((100vw - 375px) / (1920 - 375)));

    &-filtro {
        margin-bottom: calc(50px + (130 - 50) * ((100vw - 375px) / (1920 - 375)));

        &_states {
            margin: -30px 0 0;
            width: 536px;
            height: 60px;
            border-radius: 30px;
            box-shadow: 0 5px 25px 0 rgba(29, 75, 139, 0.16);
            background-color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            transform: translateX(-50%);
            left: 50%;
            z-index: 1;

            @include respond(phone) {
                width: 90%;
            }

            &:after {
                content: '\f078';
                font-family: 'Font Awesome 5 Free';
                font-weight: 900;
                background-color: purple;
                width: 32px;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $white;
                /*margin-left: 15px;*/
                border-radius: 10px;
            }

            & select {
                display: block;
                appearance: none;
                border: none;
                background-color: transparent;
                text-transform: uppercase;
                font-weight: bold;
                font-size: calc(14px + (20 - 14) * ((100vw - 375px) / (1920 - 375)));
                line-height: calc(19px + (30 - 19) * ((100vw - 375px) / (1920 - 375)));
                letter-spacing: calc(1.82px + (2.6 - 1.82) * ((100vw - 375px) / (1920 - 375)));
                padding-right: 15px;

                &::-ms-expand {
                    display: none;
                }

                &:focus {
                    outline: none;
                }
            }
        }

        &_buttons {
            display: flex;
            padding: 59px 0 0;
            text-align: center;
            justify-content: space-between;
            flex-flow: wrap;
            overflow-x: auto;

            @include respond(tab) {
                flex-flow: unset;
            }

            &::-webkit-scrollbar {
                display: none;
            }

            & a {
                font-weight: 400;
            }

            & button,
            & a {
                border: none;
                color: rgba(0, 0, 0, 0.5);
                background-color: transparent;
                text-transform: uppercase;
                font-size: calc(14px + (20 - 14) * ((100vw - 375px) / (1920 - 375)));
                line-height: calc(19px + (30 - 19) * ((100vw - 375px) / (1920 - 375)));
                letter-spacing: calc(1.82px + (2.6 - 1.82) * ((100vw - 375px) / (1920 - 375)));
                position: relative;
                padding-bottom: 25px;

                @include respond(tab) {
                    display: flex;
                    flex: none;
                    width: fit-content;
                    width: -moz-fit-content;

                    &:not(:last-child) {
                        margin-right: 20px;
                    }
                }

                &.is-checked,
                &:hover {
                    color: $black;
                    font-weight: bold;

                    &:after {
                        content: '';
                        margin: 0 auto;
                        width: 40px;
                        border-bottom: 3px solid $purple-two;
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
            }
        }
    }

    &-interno {
        padding-top: calc(75px + (250 - 75) * ((100vw - 375px) / (1920 - 375)));

        & h2 {
            font-family: Poppins;
            font-weight: bold;
            font-size: calc(25px + (30 - 25) * ((100vw - 375px) / (1920 - 375)));
            line-height: calc(30px + (45 - 30) * ((100vw - 375px) / (1920 - 375)));
            margin-bottom: calc(10px + (20 - 10) * ((100vw - 375px) / (1920 - 375)));
            color: $black;
        }

        & p {
            font-size: calc(14px + (20 - 14) * ((100vw - 375px) / (1920 - 375)));
            line-height: calc(19px + (30 - 19) * ((100vw - 375px) / (1920 - 375)));

            & img {
                width: 100%;
                height: auto;
            }
        }

        & iframe {
            width: 100% !important;
            min-height: 400px !important;
            border: 0;
        }

        &_tag {
            display: flex;
            align-items: center;
            justify-content: center;
            width: fit-content;
            min-width: calc(254px + (279 - 254) * ((100vw - 375px) / (1920 - 375)));
            height: 35px;
            border-radius: 15px;
            background-color: rgba(111, 31, 180, 0.08);
            font-size: calc(12px + (14 - 12) * ((100vw - 375px) / (1920 - 375)));
            line-height: calc(16px + (19 - 16) * ((100vw - 375px) / (1920 - 375)));
            letter-spacing: calc(1.56px + (1.82 - 1.56) * ((100vw - 375px) / (1920 - 375)));
            text-transform: uppercase;
            margin-bottom: 50px;
            padding: 0 calc(10px + (15 - 10) * ((100vw - 375px) / (1920 - 375)));

            & span {
                display: inline-block;
                margin: 0 18px;
            }
        }

        &_social {
            position: absolute;
            top: 10%;
            left: calc(-100px + (-190 - -100) * ((100vw - 375px) / (1920 - 375)));
            display: flex;
            flex-direction: column;
            text-align: center;

            & a {
                margin: 30px 0;
            }

            & i {
                font-size: 30px;
                color: $pumpkin-orange;
            }

            &-movil {
                margin-top: 30px;
                padding: 80px 0 50px;
                font-family: Poppins;
                font-weight: bold;
                font-size: 25px;
                line-height: 30px;
                border-top: 1px solid rgba(59, 59, 59, 0.1);

                & a {
                    display: inline-block;
                    margin: 30px 30px 0;
                }

                & i {
                    font-size: 25px;
                    color: $pumpkin-orange;
                }
            }
        }

        &_tags {
            color: $purpley-grey;
            font-size: calc(14px + (16 - 14) * ((100vw - 375px) / (1920 - 375)));
            line-height: calc(21px + (28 - 21) * ((100vw - 375px) / (1920 - 375)));
            margin-top: calc(45px + (60 - 45) * ((100vw - 375px) / (1920 - 375)));

            &:before {
                content: '\f02b';
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                font-size: calc(12px + (14 - 12) * ((100vw - 375px) / (1920 - 375)));
                margin-right: 15px;
            }
        }

        &_dos {
            padding-top: calc(50px + (145 - 50) * ((100vw - 375px) / (1920 - 375)));
            /*padding-bottom: calc(50px + (130 - 50) * ((100vw - 375px) / (1920 - 375)));*/

            & p {
                font-size: calc(14px + (20 - 14) * ((100vw - 375px) / (1920 - 375)));
                line-height: calc(19px + (30 - 19) * ((100vw - 375px) / (1920 - 375)));
            }

            &-text,
            &-img {
                height: calc(204px + (485 - 204) * ((100vw - 375px) / (1920 - 375)))
            }

            &-img {
                margin: 0 auto;
                width: calc(342px + (812 - 342) * ((100vw - 375px) / (1920 - 375)));
            }

            &-text {
                display: flex;
                justify-content: center;
                flex-direction: column;
            }
        }
    }

    &-articulos {
        padding-top: calc(120px + (160 - 120) * ((100vw - 375px) / (1920 - 375)));
        padding-bottom: calc(120px + (200 - 120) * ((100vw - 375px) / (1920 - 375)));
    }
}

/* Search on Top */
#search {
    height: 40px;
    font-size: 20px;
    display: inline-block;
    border: none;
    outline: none;
    color: $white;
    padding: 3px;
    padding-right: 60px;
    width: 0;
    position: absolute;
    top: 0;
    right: 80px;
    background: none;
    z-index: 3;
    transition: width .4s;
    cursor: pointer;

    @include respond(tab) {
        right: 70px;
    }

    &:focus:hover {
        border-bottom: 1px solid $white;
    }

    &:focus {
        width: calc(130px + (343 - 130) * ((100vw - 375px) / (1920 - 375)));
        z-index: 1;
        border-bottom: 1px solid $white;
        cursor: text;
    }

    &_submit {
        height: 23px;
        width: 23px;
        display: inline-block;
        float: right;
        background: url('/images/feather-search.svg') center center no-repeat;
        text-indent: -10000px;
        border: none;
        position: absolute;
        top: 12px;
        right: 80px;
        z-index: 2;
        cursor: pointer;
        opacity: 1;
        transition: opacity .4s ease;

        @include respond(tab) {
            top: 0;
            right: 70px;
        }

        &:hover {

        }
    }
}

.search {
    margin-top: -30px;

    &_input {
        width: calc(180px + (674 - 180) * ((100vw - 375px) / (1920 - 375)));
        height: calc(40px + (60 - 40) * ((100vw - 375px) / (1920 - 375)));
        border: none;
        border-radius: calc(15px + (30 - 15) * ((100vw - 375px) / (1920 - 375)));
        box-shadow: 0 5px 25px 0 rgba(29, 75, 139, 0.16);
        background-color: $white;
        padding: calc(15px + (20 - 15) * ((100vw - 375px) / (1920 - 375)));

        &:focus {
            outline: none;
        }

        &-submit {
            margin-left: 16px;
            width: calc(80px + (162 - 80) * ((100vw - 375px) / (1920 - 375)));;
            height: calc(40px + (60 - 40) * ((100vw - 375px) / (1920 - 375)));
            border: none;
            border-radius: calc(15px + (30 - 15) * ((100vw - 375px) / (1920 - 375)));
            background-color: $purple;
            font-size: calc(11px + (16 - 11) * ((100vw - 375px) / (1920 - 375)));
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: calc(1px + (2.08 - 1) * ((100vw - 375px) / (1920 - 375)));
            display: inline-block;
            color: $white;
            transition: all ease .2s;

            &:hover {
                padding-left: 15px;

                @include respond(phone) {
                    padding-left: 7px;
                }
            }


        }
    }
}


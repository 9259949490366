.directorio {
    padding-bottom: calc(120px + (213 - 120) * ((100vw - 375px) / (1920 - 375)));

    &-list {
        background-image: url("/images/six-points.svg");
        background-repeat: no-repeat;
        background-position-x: 1330px;
        background-position-y: 10px;

        & a {
            color: $white;

            :hover {
                text-decoration: none;
            }
        }

        @include respond(tab) {
            background-image: none;
        }

        & .cards-box-states {
            width: calc(164px + (261 - 164) * ((100vw - 375px) / (1920 - 375)));
        }
    }

    &-page {
        margin-top: calc(50px + (105 - 50) * ((100vw - 375px) / (1920 - 375)));

        & a {
            color: $white;

            :hover {
                text-decoration: none;
            }
        }

        & .cards-box-states {
            margin: 0 0 calc(20px + (50 - 20) * ((100vw - 375px) / (1920 - 375)));
        }
    }
}

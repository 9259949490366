.error {

    &-veda,
    &-404 {
        width: 100vw;
        height: 100vh;
        background-image: linear-gradient(146deg, $pumpkin-orange -1%, $butterscotch 73%);
    }

    &-404 {

        &_box {
            display: flex;
            justify-content: center;
            align-items: center;flex-direction: column;
            width: 100vw;
            height: 100vh;
        }

        &_logomc {
            position: absolute;
            top: 30px;
            left: 30px;
        }

        &_text {

            &-title {
                margin-top: 30px;
                text-align: center;
                font-family: Poppins;
                font-weight: bold;
                font-size: calc(30px + (60 - 30) * ((100vw - 375px) / (1920 - 375)));
                line-height: calc(50px + (80 - 50) * ((100vw - 375px) / (1920 - 375)));
                letter-spacing: calc(8px + (12 - 8) * ((100vw - 375px) / (1920 - 375)));
                color: transparent;
                -webkit-text-stroke: 2px #ffffff;
                text-transform: uppercase;
            }

            &-subtitle {
                text-align: center;
                font-family: Poppins;
                font-weight: bold;
                font-size: calc(18px + (25 - 18) * ((100vw - 375px) / (1920 - 375)));
                line-height: calc(28px + (35 - 28) * ((100vw - 375px) / (1920 - 375)));
                color: $white;
            }

            &-btn {
                margin-top: 60px;

                & a {
                    margin: 0 auto;
                    display: block;
                    padding: 15px 20px;
                    border-radius: 10px;
                    text-transform: uppercase;
                    letter-spacing: 1.82px;
                    font-weight: 700;
                    font-size: 14px;
                    text-align: center;
                    width: calc(220px + (295 - 220) * ((100vw - 375px) / (1920 - 375)));
                }
            }
        }
    }
}

// TEMPORAL
h1,h2,h3,h4,h5,h6{
	font-family: 'Poppins', Arial, Helvetica, sans-serif;
}
.rounded-20{
	border-radius: 20px;
}

.container-lg{
	max-width: 1300px;
}
.section-title{
	span{
		font-size: 12px;
	}
}

.bg-dots{
	background: {
		size: 100% 100%;
	}
	display: block;
	&.bg-dots-vertical{
		background-image: url(/images/svg/circles-yellow-v.svg);
		width: 40px;
		height: 220px;
	}
	&.bg-dots-purple-vertical{
		background-image: url(/images/svg/circles-purple-v.svg);
	}
	&.bg-dots-left{
		left: 0px;
		top: -158px;
	}
	&.bg-dots-right{
		right: 0px;
		top: -200px;
	}
	&.bg-dots-left-cut{
		left: -130px;
		top: -200px;
	}
}

.images-overlaped{
	position: relative;
	.img-bg{
		position: relative;
		max-width: 75%;
	}
	.img-fr{
		position: absolute;
		right: 40px;
    bottom: -55px;
    max-width: 40%;
	}
}

.link-more{
	a{
		font-size: 11px;
		font-weight: 800;
		i{
			font-size: 14px;
			position: relative;
			line-height: 20px;
			top: 2px;
		}
	}
}

.bg-overlay{
	opacity: 0.4;
	background-image: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0));
	display: block;
	position: absolute;
	height: 655px;
	width: 100%;
	top: 0;
	left: 0;
	&.bg-overlay-inverse{
		background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
	}
	&.bg-overlay-responsive{
		height: 100%;
	}
	&.bg-overlay-darker{
		opacity: 0.8;
	}
}
.section-banner{
	background: linear-gradient(127deg, #ff4e00 29%, #fd8324 57%);
	overflow: hidden;
	position: relative;
	.banner-title{
		color: white;
    text-shadow:
     	-1px -1px 0 #FFF,
    	1px -1px 0 #FFF,
			-1px 1px 0 #FFF,
			1px 1px 0 #FFF;
		font-family: 'Poppins';
		font-size: calc(100vw * (119/1920)); //6em;
		letter-spacing: 29px;
		padding: 90px 0;

		background: {
			position: center calc(50% - 10px);
			size: 440px auto;
			repeat: no-repeat;
		}

		&.banner-title-nosotros{
			background-image: url(/images/pages/header-nosotros.png);
		}
	}


	@supports((text-stroke: 1px $white) or (-webkit-text-stroke: 1px $white)) {
    .banner-title {
			color: transparent;
			-webkit-text-stroke: 1px $white;
    	text-stroke: 1px $white;
			text-shadow: none;
    }
	}
}

.header-interno{
	&.header-interno-nosotros{
		height: calc(420px + (950 - 420) * ((100vw - 375px) / (1920 - 375)));
	}
}

.card-item{
	height: 250px;
	.card-image{
		background: {
			size: cover;
			position: top center;

		}
		height: 200px;
		transition: all 0.3s;
	}
	.card-info{
		bottom: 0;
		width: 100%;
		transition: all 0.3s;
		p{
			height: 100px;
			&.card-description{
				font-size: 14px;
				line-height: 1.1rem;
			}
		}
	}

	&:hover{
		.card-image{
			height: 140px;
		}
		.card-info{
			padding-top: 5px !important;
			.card-title{
				text-align: left;
				margin-bottom: 5px;
			}
			.card-description{
				display: block!important;
			}
		}
	}
}
.section-actores{
	&.somos{
		background: none !important;
	}
}
.section-timeline{
	background-image: linear-gradient(324deg, $pumpkin-orange 34%, $pumpkin-orange-two 84%);
	z-index: 0;
}

.carousel-timeline{
	bottom: 0 !important;
	position: relative !important;
	padding-left:100px;

	.carousel-indicators{
		height: 100%;
    width: 100px;
    top: 0;
    left: 0;
    margin: 0;
    flex-direction: column;
		transition: all 0.3s;
		li{
			display: block;
			text-indent: 0;
			height: auto;
			width: 100%;
			background-color: transparent;
			span{
				display: block;
			}
			svg{
				display: none;
				text{
					font-size: 80px;
					font-weight: 900;
				}
			}
			&.active{
				position: relative;
				span{
					display: none;
				}
				svg{
					display: block;
				}
				&:before{
					content: " ";
					display: block;
					position: absolute;
					bottom: 120%;
					left: 70px;
					border-right: white solid 1px;
					width: 1px;
					height: 1000px;
				}
				&:after{
					content: " ";
					display: block;
					position: absolute;
					top: 120%;
					left: 70px;
					border-right: white solid 1px;
					width: 1px;
					height: 1000px;
				}
			}
		}
	}
}


/* RESPONSIVE */
@media screen and (max-width: 480px){

	.section-title{
		font-size: 20px;
	}
	.section-banner{
		.banner-title{
			font-size: 42px;
			letter-spacing: normal;
			background: {
				size: 150px auto;
				position: center center;
			}
		}
	}

	.card-item, .card-item:hover{
		height: auto;
		.card-image{
			height: 200px !important;
		}
		.card-info{
			p{
				height: auto;
			}
		}
	}

	.carousel-timeline{

		.carousel-indicators{
			li{
				span{
				}
				svg{
					text{
						font-size: 40px;
					}
				}
				&.active{
					span{
					}
					svg{
					}
					&:after, &:before{
						left: 40px;
					}
				}
			}
		}
	}
}

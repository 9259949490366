.slider {
    position: relative;


    &-box {
        height: calc(860px + (1080 - 860) * ((100vw - 375px) / (1920 - 375)));
        width: 100%;

        &-gradient {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 5;
            height: calc(860px + (1080 - 860) * ((100vw - 375px) / (1920 - 375)));
            width: 100%;
            background: linear-gradient(180deg, rgba(0,0,0,0.7959558823529411) 0%, rgba(0,0,0,0) 60%);
        }

        &_images {
            /*position: relative;
            bottom: calc(-80px + (-155 - -80) * ((100vw - 375px) / (1920 - 375)));
            text-align: center;*/
            display: flex;
            align-items: flex-end;
            height: calc(1000px + (1080 - 1000) * ((100vw - 1440px) / (1920 - 1440)));

            @include respond(desktop) {
                height: calc(910px + (1000 - 910) * ((100vw - 1200px) / (1440 - 1200)));
            }

            @include respond(tab-land) {
                height: calc(820px + (910 - 820) * ((100vw - 1024px) / (1200 - 1024)));
            }

            @include respond(tab-port) {
                height: auto;
            }

            @include respond(phone) {
                height: calc(360px + (480 - 360) * ((100vw - 320px) / (480 - 320)));
            }

            & img {
                width: 130%;

                @include respond(tab-port) {
                    width: 100%;
                }
            }
        }

        &_texts {
            margin-top: calc(94px + (130 - 94) * ((100vw - 375px) / (1920 - 375)));
            position: relative;
            z-index: 7;
        }

        &_subtitle {
            font-size: calc(14px + (16 - 14) * ((100vw - 375px) / (1920 - 375)));
            line-height: calc(19px + (21 - 19) * ((100vw - 375px) / (1920 - 375)));
            letter-spacing: calc(1.82px + (2.08 - 1.82) * ((100vw - 375px) / (1920 - 375)));
            color: $white;
            text-transform: uppercase;
            margin-bottom: calc(5px + (10 - 5) * ((100vw - 375px) / (1920 - 375)));
        }

        &_title {
            font-family: Poppins;
            font-weight: bold;
            font-size: calc(50px + (119 - 50) * ((100vw - 375px) / (1920 - 375)));
            line-height: calc(60px + (145 - 60) * ((100vw - 375px) / (1920 - 375)));
            letter-spacing: calc(0.5px + (23.8 - 0.5) * ((100vw - 375px) / (1920 - 375)));
            color: transparent;
            -webkit-text-stroke: 4px #ffffff;
            text-transform: uppercase;
            margin-top: calc(10px + (20 - 10) * ((100vw - 375px) / (1920 - 375)));
            margin-bottom: calc(5px + (10 - 5) * ((100vw - 375px) / (1920 - 375)));
        }

        &_description {
            font-family: Poppins;
            font-weight: bold;
            font-size: calc(30px + (60 - 30) * ((100vw - 375px) / (1920 - 375)));
            line-height: calc(45px + (90 - 45) * ((100vw - 375px) / (1920 - 375)));
            letter-spacing: calc(0px + (-1.8 - 0) * ((100vw - 375px) / (1920 - 375)));
            color: $white;
            margin-bottom: calc(35px + (50 - 35) * ((100vw - 375px) / (1920 - 375)));;
        }

        &_btn {
            width: fit-content;
        }
    }

    & .swiper-container-horizontal > .swiper-pagination-bullets {
        bottom: calc(270px + (300 - 260) * ((100vw - 1440px) / (1920 - 1440)));
        left: calc(190px + (250 - 190) * ((100vw - 1440px) / (1920 - 1440)));
        width: 100%;

        @include respond(desktop) {
            left: calc(160px + (190 - 160) * ((100vw - 1200px) / (1440 - 1200)));
            bottom: calc(280px + (260 - 280) * ((100vw - 1200px) / (1440 - 1200)));
        }

        @include respond(tab-land) {
            left: calc(130px + (160 - 130) * ((100vw - 992px) / (1200 - 992)));
            bottom: calc(300px + (280 - 300) * ((100vw - 992px) / (1200 - 992)));
        }

        @include respond(tab-port) {
            left: calc(15px + (130 - 15) * ((100vw - 768px) / (992 - 768)));
            bottom: calc(420px + (300 - 420) * ((100vw - 768px) / (992 - 768)));
        }

        @include respond(tab) {
            left: 15px;
            bottom: calc(435px + (420 - 435) * ((100vw - 480px) / (768 - 480)));
        }

        @include respond(phone) {
            bottom: calc(400px + (435 - 400) * ((100vw - 320px) / (480 - 320)));
        }

        @include respond(phone-small) {

        }
    }

    & .swiper-pagination {
        position: absolute;
        text-align: left;
    }

    & .swiper-pagination-bullet {
        background-color: $white;
    }

    & .swiper-pagination-bullet-active {
        background-color: $white;
    }
}


.cards {

    &-box {

        &-people {
            margin: calc(50px + (139 - 50) * ((100vw - 375px) / (1920 - 375))) 0 0;
            color: $black;
            width: calc(164px + (261 - 164) * ((100vw - 375px) / (1920 - 375)));
            position: relative;
            transition: all .2s ease;
						max-width: 100%;

            &:hover {
                transform: translateY(-10px);

                & .cards-box-people_arrow,
                & .cards-box-people_image-gradient {
                    opacity: 1;
                }
            }

            &_image {
                height: calc(257px + (409 - 257) * ((100vw - 375px) / (1920 - 375)));
                width: 100%;
                border-radius: 20px;
                overflow: hidden;
                box-shadow:
                    calc(10px + (20 - 10) * ((100vw - 375px) / (1920 - 375)))
                    calc(10px + (20 - 10) * ((100vw - 375px) / (1920 - 375)))
                    calc(10px + (40 - 10) * ((100vw - 375px) / (1920 - 375))) 0 rgba(87, 64, 2, 0.25);
                transition: all .2s ease;

                & img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }

                &-gradient {
                    border-radius: 20px;
                    opacity: 0;
                    position: absolute;
                    //width: calc(164px + (261 - 164) * ((100vw - 375px) / (1920 - 375)));
                    width: 100%;
                    height: calc(257px + (409 - 257) * ((100vw - 375px) / (1920 - 375)));
                    top: 0;
                    left: 0;
                    background: linear-gradient(180deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 60%);
										max-width: 100%;
                }
            }

            &_texts {
                font-size: calc(13px + (14 - 13) * ((100vw - 375px) / (1920 - 375)));
                line-height: 19px;
                margin-top: 15px;

                &-name {
                    font-weight: bold;
                    margin: 14px 0 5px 0;
                }

                &-position {
                    margin: 5px 0 16px 0;
                }

                &-social {

                    & ul {
                        margin: 0;
                        padding: 0;
                        list-style: none;

                        & li {
                            display: inline-block;

                            &:not(:last-child) {
                                margin-right: calc(15px + (30 - 15) * ((100vw - 375px) / (1920 - 375)));
                            }

                            & i {
                                font-size: 13px;
                                color: $black;
                            }

                            a {
                                &:hover{
                                    i{
                                        color: $pumpkin-orange;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &_arrow {
                position: absolute;
                z-index: 1;
                top: 18px;
                right: 18px;
                background-color: $pumpkin-orange;
                color: $white;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                width: 30px;
                height: 30px;
                border-radius: 5px;
                opacity: 0;
                transition: all .2s ease;
            }
        }

        &-states {
            margin: calc(50px + (139 - 50) * ((100vw - 375px) / (1920 - 375))) 0 0;
            color: $black;
            position: relative;
            border-radius: 20px;
            //width: calc(164px + (261 - 164) * ((100vw - 375px) / (1920 - 375)));
            width: 100%;
            height: calc(257px + (409 - 257) * ((100vw - 375px) / (1920 - 375)));
            box-shadow:
                calc(10px + (20 - 10) * ((100vw - 375px) / (1920 - 375)))
                calc(10px + (20 - 10) * ((100vw - 375px) / (1920 - 375)))
                calc(10px + (40 - 10) * ((100vw - 375px) / (1920 - 375))) 0 rgba(87, 64, 2, 0.25);
            overflow: hidden;
            transition: all .2s ease;

            @include respond(phone) {
                width: 100%;
            }

            &:hover {
                transform: translateY(-10px);

                & .cards-box-states_arrow {
                    opacity: 1;
                }

                & .cards-box-states_image {
                    transform: scale(1.05);
                }
            }

            &_image {
                width: 100%;
                height: 100%;
                transform: scale(1);
                transition: all .2s ease;

                & img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }

                &-gradient {
                    border-radius: 20px;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    bottom: 0;
                    left: 0;
                    background: linear-gradient(180deg, rgba(0,0,0,0) 25%, rgba(0,0,0,1) 94%);
                }
            }

            &_name {
                position: absolute;
                bottom: 40px;
                //width: calc(164px + (261 - 164) * ((100vw - 320px) / (1920 - 320)));
                width: 100%;
                text-align: center;
                font-family: Poppins;
                font-weight: bold;
                font-size: calc(12px + (24 - 12) * ((100vw - 375px) / (1920 - 375)));
                line-height: calc(22px + (43 - 22) * ((100vw - 375px) / (1920 - 375)));

                @include respond(phone) {
                    width: 100%;
                }

                &:before {
                    content: url("/images/aguila-logo-mc.svg");
                    position: absolute;
                    top: -40px;
                    left: 50%;
                    transform: translateX(-50%);
                }

                &:after {
                    content: '';
                    position: absolute;
                    width: 50px;
                    border-bottom: 1px solid #ffffff;
                    bottom: -10px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }

            &_arrow {
                position: absolute;
                z-index: 1;
                top: 18px;
                right: 18px;
                background-color: $pumpkin-orange;
                color: $white;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                width: 30px;
                height: 30px;
                border-radius: 5px;
                opacity: 0;
                transition: all .2s ease;
            }
        }

        &-fight {
            width: calc(252px + (401 - 252) * ((100vw - 375px) / (1920 - 375)));
            height: calc(398px + (632 - 398) * ((100vw - 375px) / (1920 - 375)));
            border-radius: calc(20px + (30 - 20) * ((100vw - 375px) / (1920 - 375)));
            background-color: $white;
            position: relative;
            box-shadow:
                calc(10px + (20 - 10) * ((100vw - 375px) / (1920 - 375)))
                calc(10px + (20 - 10) * ((100vw - 375px) / (1920 - 375)))
                calc(10px + (40 - 10) * ((100vw - 375px) / (1920 - 375))) 0 rgba(87, 64, 2, 0.25);
            overflow: hidden;
            transition: all .2s ease;

            &:hover {
                transform: translateY(-15px);

                & .cards-box-fight_arrow {
                    opacity: 1;
                }

                & .cards-box-fight_image {
                    transform: translateY(-15px);
                }
            }

            &_image {
                width: 100%;
                height: 100%;
                transform: translateY(0);
                transition: all .2s ease;

                & img {
                    object-fit: cover;
                    width: 100%;
                    height: auto;
                }

                &-gradient {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-image: linear-gradient(to bottom, var(--black), rgba(0, 0, 0, 0.08) 57%, rgba(0, 0, 0, 0));
                }
            }

            &_texts {
                position: absolute;
                z-index: 5;
                top: 50px;
                left: 25px;
                padding-right: 25px;
                text-align: left;
                font-size: calc(10px + (14 - 10) * ((100vw - 375px) / (1920 - 375)));
                line-height: calc(13px + (19 - 13) * ((100vw - 375px) / (1920 - 375)));
                letter-spacing: calc(1.3px + (1.82 - 1.3) * ((100vw - 375px) / (1920 - 375)));
                color: $white;

                &-title {
                    font-family: Poppins;
                    font-weight: bold;
                    font-size: calc(16px + (30 - 16) * ((100vw - 375px) / (1920 - 375)));
                    line-height: calc(20px + (45 - 20) * ((100vw - 375px) / (1920 - 375)));
                }
            }

            &_arrow {
                position: absolute;
                z-index: 1;
                top: 18px;
                right: 18px;
                background-color: $pumpkin-orange;
                color: $white;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                width: 30px;
                height: 30px;
                border-radius: 5px;
                opacity: 0;
                transition: all .2s ease;
            }
        }
    }

}

/*
    State CSS Card
 */
.state {
    min-height: 409px;

    & a {
        font-weight: normal;
    }

    &-image {
        height: 409px;
    }

    &-texts {
        position: relative;
        padding-top: 170px;
        z-index: 1;
    }

    & .cards-box-people {
        width: 261px;
    }

    & .cards-box-people_image {
        height: 409px;

        @include respond(tab) {
            height: 150px;

            & img {
                object-position: center bottom;
            }
        }
    }

    & .cards-box-people:hover .cards-box-people_arrow {
        transform: rotate(180deg);
    }

    & .cards-box-people_texts-social {
        height: 28px;
        display: inline-flex;
        align-items: center;
    }

    & .cards-box-people_arrow {
        z-index: 8;
    }

    & .cards-box-people_image {
        position: absolute;
        z-index: 5;
    }

    & .cards-box-people_texts-position,
    & .cards-box-people_texts-name {

        @include respond(tab) {
            display: none !important;
        }
    }

    &-button {
        display: none;
        position: absolute;
        right: 0;

        @include respond(tab) {
            display: inline-block !important;
        }

        & a {
            color: $white;
            font-weight: bold;
            font-size: 12px;
            border-radius: 8px;
            text-transform: uppercase;
            letter-spacing: 1.56px;
            background-color: $purple;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 28px;
            width: 108px;

            & i {
                margin-left: 5px;
                font-size: 12px;
            }
        }
    }

    &-minimize {
        height: 150px !important;

        & img {
            object-position: center bottom;
        }
    }

    &-box {


        & .state-texts {
           height: 395px;
            overflow: hidden;
        }

        &.active {

            & .state-image, & .state-texts {
                height: fit-content;
            }

            & .state-texts {
                overflow: unset;
            }
        }
    }

    &-name {
        color: $white;
        -webkit-transform: translateX(-50%) rotate(180deg);
        width: 150px;
        text-align: center;
        font-weight: bold;
        font-size: 16px;
        letter-spacing: 0.32px;

        &_fixed {
            position: absolute;
            top: 50%;
            left: 2px;
            display: none;
            transition: all .3s;

            @include respond(tab) {
                display: block !important;
            }
        }

        &_rotate {
            position: absolute;
            bottom: 100%;
            -webkit-transform: rotateZ(90deg);
            transform-origin: 0 100%;
        }
    }
}

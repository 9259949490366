.header {
    position: relative;
		&-current-state{
			font-size: 13px;
		}
    &-social {
        top: 80px;
        height: 1000px;
        position: absolute;
        z-index: 11;
        width: calc(170px + (252 - 170) * ((100vw - 992px) / (1920 - 992)));

        @include respond(tab-port) {
            display: none;
        }

        &:before {
            content: "";
            width: 1px;
            background-color: rgba(255, 255, 255, 0.5);
            position: relative;
            top: 0;
            left: 50%;
            height: 36%;
            display: block;
            transition: all 0.2s ease;

            /*@include respond(desktop) {
                left: 102px;
            }

            @include respond(tab-land) {
                left: 89px
            }

            @include respond(old-desk) {
                left: 78px
            }

            @include respond(tab-port) {

            }*/
        }

        &:after {
            content: '';
            width: 1px;
            background-color: rgba(255, 255, 255, 0.5);
            position: relative;
            left: 50%;
            bottom: 0;
            display: block;
            height: 36%;
            transition: all .2s ease;

           /* @include respond(desktop) {
                left: 102px;
            }

            @include respond(tab-land) {
                left: 88px;
            }

            @include respond(old-desk) {
                left: 78px
            }*/
        }

        & ul {
            margin: 0;
            padding: 0;

            & li {
                list-style: none;
                margin: 20px auto 15px;
                text-align: center;

                & i {
                    font-size: 18px;
                }
            }
        }
    }

    &-interno {
        background: linear-gradient(127deg, rgba(255,78,0,1) 29%, rgba(253,131,36,1) 57%);
        height: calc(300px + (550 - 300) * ((100vw - 375px) / (1920 - 375)));
        display: flex;
        align-items: center;

        &_img {
            margin: 0 auto;
            width: calc(184px + (674 - 187) * ((100vw - 375px) / (1920 - 375)));
            height: calc(81.7px + (300 - 81.7) * ((100vw - 375px) / (1920 - 375)));
            border-radius: calc(20px + (30 - 20) * ((100vw - 375px) / (1920 - 375)));
            position: relative;

            &-news {
                margin: 0 auto;
                top: calc(134px + (124 - 134) * ((100vw - 375px) / (1920 - 375)));
                width: calc(346px + (1177 - 346) * ((100vw - 375px) / (1920 - 375)));
                height: calc(237px + (628 - 237) * ((100vw - 375px) / (1920 - 375)));
                border-radius: calc(20px + (30 - 20) * ((100vw - 375px) / (1920 - 375)));
                position: absolute;
                z-index: 1;
                box-shadow: 10px 10px 40px 0 rgba(11, 78, 24, 0.1);
                transform: translateX(-50%);
                left: 50%;

                &_img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: calc(20px + (30 - 20) * ((100vw - 375px) / (1920 - 375)));
                }

                & .btns-back {
                    position: absolute;
                    top: -50px;
                }
            }

            &-image {
                width: calc(184px + (674 - 187) * ((100vw - 375px) / (1920 - 375)));
                height: calc(81.7px + (300 - 81.7) * ((100vw - 375px) / (1920 - 375)));
                border-radius: calc(20px + (30 - 20) * ((100vw - 375px) / (1920 - 375)));
                object-fit: cover;
            }

            &-dots {
                position: absolute;
                z-index: 2;
                top: -33px;
                right: calc(30px + (65 - 30) * ((100vw - 375px) / (1920 - 375)));
                transform: translateX(-50%);

                @include respond(tab) {
                    display: none;
                }
            }

            &-color {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: $purple;
                opacity: 0.88;
                border-radius: calc(20px + (30 - 20) * ((100vw - 375px) / (1920 - 375)));
            }
        }

        &_title {
            font-family: Poppins;
            font-weight: bold;
            font-size: calc(50px + (119 - 50) * ((100vw - 375px) / (1920 - 375)));
            line-height: calc(80px + (145 - 80) * ((100vw - 375px) / (1920 - 375)));
            letter-spacing: calc(0.8px + (47.6 - 0.8) * ((100vw - 375px) / (1920 - 375)));
            color: transparent;
            -webkit-text-stroke: calc(1.7px + (2.5 - 1.7) * ((100vw - 375px) / (1920 - 375))) #ffffff;
            text-transform: uppercase;
            transform: translate(-50%, -50%);
            position: absolute;
            left: 50%;
            top: 50%;

            &:after {
                content: '';
                margin-left: calc(-0.8px + (-47.6 - -0.8) * ((100vw - 375px) / (1920 - 375)));
            }

        }

        &_small {
            background: linear-gradient(127deg, rgba(255,78,0,1) 29%, rgba(253,131,36,1) 57%);
            height: calc(300px + (400 - 300) * ((100vw - 375px) / (1920 - 375)));
            display: flex;
            align-items: center;
            position: relative;

            &-points {
                position: absolute;
                bottom: 0;
                right: calc(260px + (455 - 260) * ((100vw - 375px) / (1920 - 375)));

                @include respond(tab) {
                    display: none;
                }
            }

            &-gradient {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(180deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 100%);
            }

            &-title {
                font-family: Poppins;
                font-weight: bold;
                font-size: calc(25px + (60 - 25) * ((100vw - 375px) / (1920 - 375)));
                line-height: calc(25px + (90 - 25) * ((100vw - 375px) / (1920 - 375)));
                color: $white;

                @include respond(tab-port) {
                    margin-top: 10px;
                }

                &_news {
                    font-family: Poppins;
                    font-weight: bold;
                    font-size: calc(20px + (30 - 20) * ((100vw - 375px) / (1920 - 375)));
                    line-height: calc(25px + (45 - 25) * ((100vw - 375px) / (1920 - 375)));
                    color: $white;
                    width: calc(342px + (812 - 342) * ((100vw - 375px) / (1920 - 375)));
                }
            }

            &-subtitle {
                font-size: calc(12px + (16 - 12) * ((100vw - 375px) / (1920 - 375)));
                line-height: calc(16px + (21 - 16) * ((100vw - 375px) / (1920 - 375)));
                letter-spacing: calc(1.56px + (2.08 - 1.56) * ((100vw - 375px) / (1920 - 375)));
                color: $white;
                text-transform: uppercase;
                margin-top: calc(0px + (60 - 0) * ((100vw - 375px) / (1920 - 375)));

                &_news {
                    margin-top: calc(10px + (19 - 10) * ((100vw - 375px) / (1920 - 375)));
                }
            }
        }

        &_big {
            background: linear-gradient(127deg, rgba(255,78,0,1) 29%, rgba(253,131,36,1) 57%);
            height: calc(482px + (700 - 482) * ((100vw - 375px) / (1920 - 375)));

            @include respond(tab-port) {
                height: auto;
                padding-bottom: calc(80px + (200 - 80) * ((100vw - 375px) / (992 - 375)));
                margin-bottom: calc(-50px + (150 - -50) * ((100vw - 375px) / (992 - 375)));
            }

            &-img,
            &-texts {
                height: calc(482px + (700 - 482) * ((100vw - 375px) / (1920 - 375)));
            }

            &-img {
                position: relative;

                @include respond(tab-port) {
                    width: 91%;
                    height: auto;
                    position: absolute;
                    transform: translateX(-50%);
                    left: 50%;
                    bottom: calc(-300px + (-600 - -300) * ((100vw - 375px) / (992 - 375)));
                }

                & img {
                    position: absolute;
                    bottom: calc(-10px + (-155 - -10) * ((100vw - 992px) / (1920 - 992)));
                }
            }
        }
    }
}
